@import '../color_variables.scss';
@import '../size_variables.scss';

.user-list {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  overflow: auto;
  // max-height: 62vh;
  box-shadow: 4px 4px 11px #656565;
  border-radius: 0.5em;
  padding: 0.5em 1em 0;
  background: #f1f1f1;

  // @media (max-width: $medium-width-breakpoint) and (min-height: $high-height-breakpoint) {
  //   max-height: 65vh;
  // }
  //
  // @media (max-width: $small-width-breakpoint) and (min-height: $high-height-breakpoint) {
  //   max-height: 75vh;
  // }
  //
  // @media (max-width: $smaller-width-breakpoint) and (min-height: $medium-height-breakpoint) {
  //   max-height: 68vh;
  // }
  //
  // @media (max-width: $smaller-width-breakpoint) and (min-height: $high-height-breakpoint) {
  //   max-height: 77vh;
  // }
  //
  // @media (max-height: $medium-height-breakpoint) {
  //   max-height: 55vh;
  // }
  //
  // @media (max-height: $small-height-breakpoint) {
  //   max-height: 40vh;
  // }

  @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
    padding: 0.5em 0.4em 0;
    border-radius: 4px;
  }

  // &::-webkit-scrollbar {
  //   width: 0.8em;
  //   height: 0.8em;
  //
  //   @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
  //     width: 0.4em;
  //     height: 0.4em;
  //   }
  // }
  //
  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
  //   border-radius: 0.4em;
  // }
  //
  // &::-webkit-scrollbar-thumb {
  //   background-color: $default-black-color;
  //   border-radius: 0.4em;
  // }

  &__user {
    border-radius: 2em 0.5em 0.5em 2em;

    &--rounded {
      border-radius: 0.5em;
    }

    &,
    &--rounded {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
      background: white;
      max-height: 5em;
      margin: 1em 0 1em 1.4em;
      border: 3px solid $default-black-color;
      padding: 0 1em 0 0;
      flex: 0 0;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
        margin: 0.6em 0 0.6em 1.1em;
        height: auto;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 0.79em;
        border-width: 1px;
        padding: 0 0.5em 0 0;
        margin: 0.5em 0 0.5em 0.6em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.75em;
        padding: 0 0 0 0.5em;
        border-radius: 4px;
        margin: 0.3em 0;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 0.65em;
      }

      &__picture-container {
        position: relative;
        left: -1.5em;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: center;
        border: 3px solid $default-black-color;
        color: white;
        background: $default-black-color;
        border-radius: 50%;
        overflow: hidden;
        width: 6em;
        height: 6em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          width: 5em;
          height: 5em;
          left: -1.3em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          display: none;
        }
      }

      &__picture-icon {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 5em;
        padding: 0.3em 0.3em 0;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 4em;
        }
      }

      &__name {
        font-family: 'Iceland', cursive;
        font-size: 2.6em;
        flex: 1;
        color: $default-black-color;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 2.4em;
        }

        @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 2.2em;
        }
      }

      &__spacer {
        display: block;
        height: 100%;
        width: 1.5em;
      }
    }
  }
}
