@import '../color_variables.scss';
@import '../size_variables.scss';

$scale-reference-1: #a50026;
$scale-reference-2: #d73027;
$scale-reference-3: #f46d43;
$scale-reference-4: #fdae61;
$scale-reference-5: #fee08b;
$scale-reference-6: #d9ef8b;
$scale-reference-7: #a6d96a;
$scale-reference-8: #66bd63;
$scale-reference-9: #1a9850;
$scale-reference-10: #006837;

.start-class {
  flex: 1;
  padding: 7em 3.5em 3.5em;
  min-width: 0;

  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.4em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-black-color;
    border-radius: 0.4em;
  }

  @media (max-height: $small-height-breakpoint) {
    overflow: auto;
  }

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__instructions {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.6em;
    font-size: 1.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__user-action-button {
    font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1em;
    padding: 1em;
    margin: 0.5em 0;

    @media (max-width: $small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1.1em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      border-radius: 4px;
      align-self: stretch;
      margin: 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }
  }

  &__not-found-text {
    font-family: 'Montserrat', sans-serif;
    color: lighten($default-black-color, 20%);
    text-align: center;
    font-size: 1.5em;
    padding: 0.8em 0;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.75em;
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid $default-black-color;
    border-width: 3px 0 0;
    margin: 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      border-width: 2px 0 0;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__selected-coach,
  &__selected-training,
  &__selected-time {
    font-family: 'Iceland', cursive;
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;
    font-size: 2.5em;
    color: $default-black-color;
    margin: 0 0 0.5em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 2.2em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.8em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1.6em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1.4em;
    }

    &__label {
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 0.2em 0 0;
      flex: 0;
      white-space: nowrap;
    }
  }

  &__selected-coach {
    margin: 0;
  }

  &__confirm-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 3em 0 0;

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      margin: 2em 0 0;
    }

    @media (max-width: $small-width-breakpoint) {
      margin: 1.5em 0 0;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 1em 0 0;
    }
  }

  &__confirm-button {
    padding: 0.2em 0;

    @media (max-width: $small-width-breakpoint) {
      border-radius: 3px;
    }
  }

  &__coach-list,
  &__training-list,
  &__class-time-list {
    &::-webkit-scrollbar {
      width: 0.8em;
      height: 0.8em;

      @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
        width: 0.4em;
        height: 0.4em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.4em;
    }
  }

  &__coach-list {
    max-height: calc(100vh - 21em);

    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 19em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 13em);
    }

    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
  }

  &__training-list {
    max-height: calc(100vh - 21em);

    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 19em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 13em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 11em);
    }

    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 11em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 11em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 11em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 11em);
    }
  }

  &__class-time-list {
    max-height: calc(100vh - 24em);

    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 21em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 18em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 15.5em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 13.2em);
    }

    @media (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12.8em);
    }

    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12.8em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16.8em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 16.5em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12.8em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 17.5em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 16.5em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 18em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 17em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 13em);
    }
  }

  &__add-couach-button {
    font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1em;
    padding: 1em;
    margin: 0.2em 0 0.5em;
    border-radius: 4px;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.95em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.7em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      align-self: stretch;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }
}
