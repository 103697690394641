@import '../color_variables.scss';
@import '../size_variables.scss';

$scale-reference-1: #a50026;
$scale-reference-2: #d73027;
$scale-reference-3: #f46d43;
$scale-reference-4: #fdae61;
$scale-reference-5: #fee08b;
$scale-reference-6: #d9ef8b;
$scale-reference-7: #a6d96a;
$scale-reference-8: #66bd63;
$scale-reference-9: #1a9850;
$scale-reference-10: #006837;

.student-checkin {
  flex: 1;
  padding: 7em 3em 3.5em;
  min-width: 0;

  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.4em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-black-color;
    border-radius: 0.4em;
  }

  @media (max-height: $small-height-breakpoint) {
    overflow: auto;
    max-height: 87vh;
  }

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__instructions {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.6em;
    font-size: 1.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__options {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    height: 50%;

    @media (max-width: $medium-less-width-breakpoint) {
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: center;
    }
  }

  &__option-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    padding: 1.5em;
    flex: 1;

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      padding: 1.2em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
      padding: 0.8em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      padding: 0.4em;
    }
  }

  &__user-action-button {
    font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1em;
    padding: 1em;

    @media (max-width: $small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1.1em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      box-shadow: none;
      border-radius: 4px;
      align-self: stretch;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }
  }

  &__not-found-text {
    font-family: 'Montserrat', sans-serif;
    color: lighten($default-black-color, 20%);
    text-align: center;
    font-size: 1.5em;
    padding: 0.8em 0;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.75em;
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid $default-black-color;
    border-width: 3px 0 0;
    margin: 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      border-width: 2px 0 0;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      border-width: 1px 0 0;
    }
  }

  &__warning-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    text-align: justify;
    padding: 0;
    background: white;
    border-radius: 3px;
    margin: 0.5em 0 1.2em;

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.8em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0.5em 0 0.6em;
    }

    &__icon {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0.5em 0.65em;
      font-size: 1.5em;
      background: $site-red-color;
      margin: 0 0.5em 0 0;
      border-radius: 3px 0 0 3px;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 1.1em;
        padding: 0.5em 0.45em;
      }
    }

    &__text {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: $default-black-color;
      font-weight: bold;
      text-align: justify;
      padding: 1em 1em 1em 0;
      flex: 1;

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
        padding: 0.6em 0.6em 0.6em 0;
      }

      @media (max-width: $tiny-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }

  &__checkin-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: stretch;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: space-between;

      &__student-name {
        font-family: 'Iceland', cursive;
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;
        font-size: 2.3em;
        color: $default-black-color;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 0.85em;
        }
      }

      &__label {
        text-transform: uppercase;
        font-weight: bold;
        margin: 0 0.2em 0 0;
      }

      &__student-picture-container {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: center;
        border: 3px solid $default-black-color;
        color: white;
        background: $default-black-color;
        border-radius: 50%;
        overflow: hidden;
        width: 7em;
        height: 7em;
        flex-shrink: 0;
        align-self: center;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.75em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 0.6em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.47em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 0.35em;
        }
      }

      &__student-picture-icon {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 5.5em;
        padding: 0.3em 0.3em 0;
      }
    }

    &__scale {
      font-family: 'Montserrat', sans-serif;
      margin: 0.6em 0 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;

      &__title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.6em;
        color: $default-black-color;
        // margin: 0 0 0.5em;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.85em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 0.75em;
        }
      }

      &__highlighted-title {
        text-transform: uppercase;
        font-weight: bold;
        margin: 0 0.4em 0 0;
        flex: 0 1;
        white-space: nowrap;
      }

      &__ranges {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        overflow: auto;

        @media (max-width: $very-small-width-breakpoint) {
          max-width: 400px;
        }

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $default-black-color;
          border-radius: 0.4em;
        }
      }

      &__range {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: stretch;
        list-style: none;
        flex: 1 1;

        &__image {
          &--1,
          &--2,
          &--3,
          &--4,
          &--5,
          &--6,
          &--7,
          &--8,
          &--9,
          &--10 {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            font-size: 3.5em;
            padding: 0.2em 0;
            border: 0 solid white;
            border-right-width: 1px;
            border-left-width: 1px;
            background: $default-black-color;

            @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
              font-size: 3em;
            }

            @media (max-width: $medium-less-width-breakpoint) {
              font-size: 2.5em;
              border-right-width: 0;
            }

            @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
              font-size: 2.2em;
            }

            @media (max-width: $very-small-width-breakpoint) {
              font-size: 2em;
            }
          }

          &--1 {
            color: $scale-reference-1;
          }
          &--2 {
            color: $scale-reference-2;
          }
          &--3 {
            color: $scale-reference-3;
          }
          &--4 {
            color: $scale-reference-4;
          }
          &--5 {
            color: $scale-reference-5;
          }
          &--6 {
            color: $scale-reference-6;
          }
          &--7 {
            color: $scale-reference-7;
          }
          &--8 {
            color: $scale-reference-8;
          }
          &--9 {
            color: $scale-reference-9;
          }
          &--10 {
            color: $scale-reference-10;
          }

          &__text {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 0.4em 0.2em 0;
            font-size: 0.3em;
            height: 2em;

            @media (max-width: $small-width-breakpoint) {
              height: 3em;
            }
          }
        }

        &:first-of-type &__image {
          &--1,
          &--2,
          &--3,
          &--4,
          &--5,
          &--6,
          &--7,
          &--8,
          &--9,
          &--10 {
            border-radius: 0.1em 0 0 0;
            border-left-width: 0;
          }
        }

        &:last-of-type &__image {
          &--1,
          &--2,
          &--3,
          &--4,
          &--5,
          &--6,
          &--7,
          &--8,
          &--9,
          &--10 {
            border-radius: 0 0.1em 0 0;
            border-right-width: 0;
          }
        }

        &__options {
          display: flex;
          flex-flow: row nowrap;
          align-items: stretch;
          justify-content: stretch;
          flex: 1;
        }

        &__option {
          &--1,
          &--2,
          &--3,
          &--4,
          &--5,
          &--6,
          &--7,
          &--8,
          &--9,
          &--10 {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 0.6em 0.2em;
            outline: none;
            cursor: pointer;
            -webkit-tap-highlight-color:  transparent;
            border: none;
            user-select: none;
            font-size: 1em;
            text-decoration: none;
            min-width: 35px;
            min-height: 35px;
            font-weight: bold;
            border: 1px solid $default-black-color;
            background: $default-black-color;
            flex: 1 1;

            @media (max-width: $medium-less-width-breakpoint) {
              border-right-width: 0;
            }

            &:disabled {
              cursor: default;
              color: white;
            }
          }

          &--1 {
            color: $scale-reference-1;
            border-color: $scale-reference-1;

            &:disabled {
              background: $scale-reference-1;
            }
          }
          &--2 {
            color: $scale-reference-2;
            border-color: $scale-reference-2;

            &:disabled {
              background: $scale-reference-2;
            }
          }
          &--3 {
            color: $scale-reference-3;
            border-color: $scale-reference-3;

            &:disabled {
              background: $scale-reference-3;
            }
          }
          &--4 {
            color: $scale-reference-4;
            border-color: $scale-reference-4;

            &:disabled {
              background: $scale-reference-4;
            }
          }
          &--5 {
            color: $scale-reference-5;
            border-color: $scale-reference-5;

            &:disabled {
              background: $scale-reference-5;
            }
          }
          &--6 {
            color: $scale-reference-6;
            border-color: $scale-reference-6;

            &:disabled {
              background: $scale-reference-6;
            }
          }
          &--7 {
            color: $scale-reference-7;
            border-color: $scale-reference-7;

            &:disabled {
              background: $scale-reference-7;
            }
          }
          &--8 {
            color: $scale-reference-8;
            border-color: $scale-reference-8;

            &:disabled {
              background: $scale-reference-8;
            }
          }
          &--9 {
            color: $scale-reference-9;
            border-color: $scale-reference-9;

            &:disabled {
              background: $scale-reference-9;
            }
          }
          &--10 {
            color: $scale-reference-10;
            border-color: $scale-reference-10;

            &:disabled {
              background: $scale-reference-10;
            }
          }
        }

        &__option-text,
        &__option-number {
          &,
          &--flex {
            font-family: 'Montserrat', sans-serif;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            text-shadow: 0 0 2px $default-black-color;
          }

          &--flex {
            flex: 1;
          }
        }

        &__option-number {
          &,
          &--flex {
            font-size: 1.5em;

            @media (max-width: $very-small-width-breakpoint) {
              font-size: 1em;
            }
          }
        }

        &__option-text {
          &,
          &--flex {
            @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
              font-size: 0.75em;
            }

            @media (max-width: $medium-less-width-breakpoint) {
              font-size: 0.6em;
            }

            @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
              display: none;
            }
          }
        }
      }
    }

    &__confirm-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 3em 0 0;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        margin: 2em 0 0;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 1.5em 0 0;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $medium-height-breakpoint) {
        margin: 1em 0 0;
      }
    }

    &__confirm-button {
      padding: 0.2em 0;

      @media (max-width: $small-width-breakpoint) {
        border-radius: 3px;
      }

      @media (max-height: $high-height-breakpoint) {
        font-size: 3em;
      }

      @media (max-height: $medium-height-breakpoint) {
        font-size: 2.8em;
      }
    }
  }

  &__user-list {
    max-height: calc(100vh - 21em);

    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 19em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 13em);
    }

    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
  }

  &__schedule {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;

    &__button {
      padding: 0.4em 0.6em;
      font-size: 2em;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 1.8em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.5em;
      }

      &__icon {
        margin: 0 0.2em 0 0;
      }
    }
  }

  &__overlay-note {
    margin: 0.3em 0 0.5em ;
    background: #dcd6c3;
    padding: 0.4em;
    border: 1px solid #b3a476;
    border-radius: 3px;

    &__text {
      color: #564f36;
      text-align: justify;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.9em;
      }
    }
  }
}
