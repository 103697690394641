@import '../color_variables.scss';
@import '../size_variables.scss';

.default-header {
  background: $default-black-color;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 1px 4px 0 lighten($default-black-color, 10%);
  z-index: 1;

  &__logo-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    max-width: 9em;
    border: 4px solid $background-color;
    border-radius: 50%;
    padding: 0.3em;
    background: $default-black-color;
    margin: 0.3em 0 0;
    box-shadow: 0 1px 6px 1px lighten($default-black-color, 10%);

    @media (max-width: $medium-width-breakpoint) {
      max-width: 8em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      max-width: 7em;
    }

    @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
      max-width: 6em;
    }

    @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
      max-width: 4.2em;
    }
  }

  &__logo {
    width: 100%;
  }

  &__left-menu-bar,
  &__right-menu-bar {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-self: center;
  }

  &__left-menu-bar {
    justify-content: flex-start;
    left: 2em;

    @media (max-width: $medium-less-width-breakpoint) {
      left: 1.36em;
    }

    @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
      left: 0.75em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      left: 0.36em;
    }
  }
  &__right-menu-bar {
    justify-content: flex-end;
    right: 1.5em;

    @media (max-width: $medium-less-width-breakpoint) {
      right: 1.36em;
    }

    @media (max-width: $small-width-breakpoint) {
      right: 0.65em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      right: 0.36em;
    }
  }

  &__back-button,
  &__menu-button,
  &__operational-tasks-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    outline: none;
    cursor: pointer;
    -webkit-tap-highlight-color:  transparent;
    border: none;
    user-select: none;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    color: white;
    background: none;
    text-transform: uppercase;
    padding: 0.3em 0.5em;
  }

  &__back-button {
    font-size: 2em;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.7em;
    }

    @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 1.2em;
    }

    &__icon:not(:last-child) {
      margin: 0 0.3em 0 0;
    }
  }

  &__menu-button,
  &__operational-tasks-button {
    font-size: 1.5em;

    @media (max-width: $medium-less-width-breakpoint) {
      font-size: 1.4em;
    }

    @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 1.1em;
    }
  }

  &__menu {
    top: 100%;
    right: 0.45em;
    background: white;
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    border-radius: 3px;
    box-shadow: 2px 2px 9px #444444a6;
    font-family: 'Montserrat', sans-serif;
    cursor: default;

    &__option {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      text-decoration: none;
      text-transform: none;
      color: #22677b;
      font-weight: bold;
      cursor: pointer;
      padding: 0.6em 0.8em;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }

      &__text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }
    }

    &__header-text {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 0.9em;
      text-decoration: none;
      text-transform: none;
      color: #696969;
      background: #c1c1c1;
      border-radius: 3px 3px 0 0;
      font-weight: bold;
      cursor: default;
      padding: 0.6em 0.8em;
      white-space: nowrap;

      @media (max-width: $medium-less-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
        font-size: 0.8em;
      }
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #22677b4d;
    border-width: 1px 0 0;
    margin: 0;

    // @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
    //   margin: 0.8em 0;
    // }
    //
    // @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    //   margin: 0.4em 0;
    // }
  }
}
