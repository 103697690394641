.default-menu-layout {
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template: "header" 4.5em "main" auto / auto;
  justify-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
  background: #e8e8e8;
  overflow: hidden; }
  @media (max-width: 850px) {
    .default-menu-layout {
      grid-template: "header" 4em "main" auto / auto; } }
  @media (max-height: 850px), (max-width: 680px) {
    .default-menu-layout {
      grid-template: "header" 3.5em "main" auto / auto; } }
  @media (max-height: 480px), (max-width: 510px) {
    .default-menu-layout {
      grid-template: "header" 3em "main" auto / auto; } }
  .default-menu-layout__header {
    grid-area: header; }
  .default-menu-layout__main {
    grid-area: main;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    min-width: 0; }
