@import '../color_variables.scss';
@import '../size_variables.scss';

.operational-tasks {
  flex: 1;
  padding: 7em 3.5em 3.5em;
  min-width: 0;

  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.4em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-black-color;
    border-radius: 0.4em;
  }

  @media (max-height: $small-height-breakpoint) {
    overflow: auto;
  }

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__actions-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0.8em;
  }

  &__add-button {
    font-size: 2em;
    padding: 0.2em;
    flex: none;
    border-radius: 3px;

    @media (max-width: $large-width-breakpoint) {
      font-size: 1.7em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
      font-size: 1.3em;
      border-radius: 3px;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
      font-size: 1em;
    }

    &:not(:last-of-type) {
      margin: 0 0.6em 0 0;
    }

    &__icon {
      margin: 0 0.4em 0 0;
    }
  }

  &__list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    list-style: none;
    margin: 0 0 1.2em;
    background: #f9f9f9;
    border: 1px solid #b5b5b5;
    border-radius: 3px;
    padding: 0.4em;
    max-height: calc(100vh - 21em);
    overflow: auto;

    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 19em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 13em);
    }

    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      font-family: 'Iceland', cursive;
      color: #626262;
      font-weight: bold;

      &__initiated,
      &__finished {
        text-align: right;
      }
    }

    &::-webkit-scrollbar {
      width: 0.8em;
      height: 0.8em;

      @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
        width: 0.4em;
        height: 0.4em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 3px;
    }
  }

  &__task  {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &__wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      padding: 0.1em 0;

      // &:not(:last-child) {
      //   margin: 0 0 0.2em;
      // }
      &:nth-child(even) {
        background: #a5a5a530;
      }
      &:nth-child(odd) {
        background: #ffffff30;
      }
    }

    &__priority-indicator {
      &--1,
      &--2,
      &--3 {
        align-self: stretch;
        width: 0.2em;
        border-radius: 2px;
        margin: 0 0.2em 0 0;
      }

      &--1 {
        background: #8d8d8d;
      }
      &--2 {
        background: $default-alert-color;
      }
      &--3 {
        background: #cd4a4a;
      }
    }

    &__main {
      cursor: pointer;

      &,
      &--disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: baseline;
        justify-content: flex-start;
      }
    }

    &__input-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-end;
      align-self: flex-start;
    }

    &__input {
      &,
      &--blue,
      &--green {
        cursor: pointer;
        -webkit-tap-highlight-color:  transparent;
        outline: none;
        background: white;
        min-width: 30px;
        min-height: 30px;
        border: 1px solid #b5b5b5;
        border-radius: 3px;
        margin: 0;

        &:disabled {
          cursor: default;
        }
      }

      &--blue {
        &:disabled {
          color: #4887e9;
          border-color: #4887e9;
        }
      }
      &--green {
        &:disabled {
          color: #73af34;
          border-color: #73af34;
        }
      }

      &__icon {
        font-size: 1.3em;
      }
    }

    &__expandable-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }

      &__header {
        &,
        &--red,
        &--yellow {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          -webkit-tap-highlight-color:  transparent;
        }

        &__visible-icon {
          &,
          &--hidden {
            font-size: 0.7em;
            align-self: center;
            margin: 0 0.4em 0 0;
          }

          &--hidden {
            visibility: hidden;
          }
        }
      }

      &__content {
        background: white;
        border-radius: 3px;
        border: 1px solid #b5b5b5;
        padding: 0.2em 0.4em;
        margin: 0.2em 0 0;
      }
    }

    &__commentary {
      white-space: pre-wrap;
    }
  }

  &__list__header__name,
  &__task__main,
  &__task__main--disabled {
    flex: 1;
  }

  &__list__header__initiated,
  &__list__header__finished,
  &__task__input-wrapper {
    width: 4.6em;
  }

  &__no-data-found {
    background: white;
    border-radius: 3px;
    padding: 0.4em;
    font-family: 'Iceland', cursive;

    &__text {
      text-align: center;
      color: #626262;
    }
  }

  &__confirmation-window {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      &__title {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #3a3839;
        font-size: 2em;
        text-transform: uppercase;
        text-align: center;

        @media (max-width: $large-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__red-text {
        color: #a75050;
      }
    }

    &__content {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 0 1em 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0 0.4em 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.25em;
          height: 0.25em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #3a3839;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 1.3em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &__horizontal-rule {
      width: 100%;
      border: 0 solid #3a3839;
      border-width: 2px 0 0;
      margin: 0.5em 0;

      @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
        border-width: 1px 0 0;
      }
    }

    &__description {
      text-align: justify;
      margin: 0 0 0.4em;
      color: #353535;
      background: #dbdbdb;
      border-radius: 3px;
      border: 1px solid #f5f5f5;
      padding: 0.3em 0.5em;
    }
  }
}
