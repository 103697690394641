@import '../color_variables.scss';
@import '../size_variables.scss';

.default-menu-layout {
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template:
    "header" 4.5em
    "main" auto
    / auto;
  justify-items: stretch;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
  background: $background-color;
  overflow: hidden;

  @media (max-width: $medium-less-width-breakpoint) {
    grid-template:
      "header" 4em
      "main" auto
      / auto;
  }

  @media (max-height: $high-height-breakpoint), (max-width: $small-width-breakpoint) {
    grid-template:
      "header" 3.5em
      "main" auto
      / auto;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    grid-template:
      "header" 3em
      "main" auto
      / auto;
  }

  &__header {
    grid-area: header;
  }

  &__main {
    grid-area: main;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    min-width: 0;
  }
}
