@import '../color_variables.scss';
@import '../size_variables.scss';

.early-checkin {
  flex: 1;
  padding: 5.5em 1.5em 0.4em;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1.5em 0.4em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 3.5em 1em 1em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 3em 0.5em 1em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 3em 0.5em 0.5em;
  }

  @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
    padding: 2em 0.5em 0.5em;
  }

  &__confirm-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 3em 0 0;
  }

  &__confirm-button {
    padding: 0.2em 0;
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid $default-black-color;
    border-width: 2px 0 0;
    margin: 0.3em 0;

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      border-width: 1px 0 0;
      margin: 0.2em 0;
    }
  }

  &__list-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0.5em 0 0;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #b1b1b1;
    overflow: auto;
    max-height: calc(100vh - 15em);
    border: 1px solid $default-black-color;

    @media (max-width: $small-width-breakpoint) {
      padding: 0.5em;
    }

    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 13em);
    }
    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 10.5em);
    }

    &::-webkit-scrollbar {
      width: 1.8em;
      height: 1.8em;

      @media (max-width: $medium-less-width-breakpoint) {
        width: 0.4em;
        height: 0.4em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.2em;
    }
  }

  &__time-entry,
  &__experimental-entry {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border: 0 solid $default-black-color;
    padding: 0.2em 0;

    &:not(:last-of-type) {
      border-bottom-width: 1px;
    }

    &__label-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
    }

    &__link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 1.2em;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      border: none;
      user-select: none;
      text-decoration: none;
      color: $default-black-color;
      background: none;
      min-width: 35px;
      min-height: 35px;
      padding: 0.5em;
      cursor: pointer;
    }

    &__name,
    &__hour,
    &__time,
    &__count {
      font-family: 'Montserrat', sans-serif;
      color: $default-black-color;
      font-size: 1.7em;
      font-weight: bold;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.6em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }
    }
  }

  &__tab-selector {
    margin: 0.5em 0 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;

    &__button {
      background: $default-black-color;
      color: white;
      cursor: pointer;

      &,
      &:disabled {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-family: 'Iceland', cursive;
        font-size: 2em;
        outline: none;
        -webkit-tap-highlight-color:  transparent;
        border: none;
        user-select: none;
        text-decoration: none;
        min-width: 35px;
        min-height: 35px;
        padding: 0.4em 0.8em;
        border-radius: 5px 5px 0 0;

        &:not(:first-child) {
          margin: 0 0 0 0.5em;
        }

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.95em;
        }
      }

      &:disabled {
        background: white;
        color: $default-black-color;
        cursor: default;
      }
    }
  }

  &__tab-content {
    background: white;
    padding: 0.5em;
    border-radius: 0 5px 5px 5px;
  }

  &__no-entry-text {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    font-size: 1.5em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.4em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1.2em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1em;
    }
  }
}
