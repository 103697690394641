@import '../color_variables.scss';
@import '../size_variables.scss';

$scale-reference-1: #006837;
$scale-reference-2: #1a9850;
$scale-reference-3: #66bd63;
$scale-reference-4: #a6d96a;
$scale-reference-5: #d9ef8b;
$scale-reference-6: #fee08b;
$scale-reference-7: #fdae61;
$scale-reference-8: #f46d43;
$scale-reference-9: #d73027;
$scale-reference-10: #a50026;

$default-animation-period: 0.4s;

.last-class {
  flex: 1;
  padding: 6em 2.5em 1em;
  min-width: 0;

  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.4em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-black-color;
    border-radius: 0.4em;
  }

  @media (max-height: $small-height-breakpoint) {
    overflow: auto;
    max-height: 87vh;
  }

  @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 6em 2.5em 1em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 1em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 1em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 0.5em 1em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__student-list {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    overflow: auto;
    height: calc(100vh - 16em);
    max-height: calc(100vh - 16em);
    box-shadow: 4px 4px 11px #656565;
    border-radius: 0.5em;
    padding: 0.5em 1em 0;
    background: #f1f1f1;

    @media (max-width: $medium-less-width-breakpoint) {
      height: calc(100vh - 13.5em);
      max-height: calc(100vh - 13.5em);
    }
    @media (max-width: $small-width-breakpoint) {
      height: calc(100vh - 12em);
      max-height: calc(100vh - 12em);
      padding: 0.5em 0.5em 0;
    }
    @media (max-width: $smaller-width-breakpoint) {
      height: calc(100vh - 9em);
      max-height: calc(100vh - 9em);
    }

    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      height: calc(100vh - 9.5em);
      max-height: calc(100vh - 9.5em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $high-height-breakpoint) {
      height: calc(100vh - 12em);
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      height: calc(100vh - 10em);
      max-height: calc(100vh - 10em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      height: calc(100vh - 14em);
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      height: calc(100vh - 12.5em);
      max-height: calc(100vh - 12.5em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      height: calc(100vh - 10em);
      max-height: calc(100vh - 10em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      height: calc(100vh - 14em);
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      height: calc(100vh - 13em);
      max-height: calc(100vh - 13em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      height: calc(100vh - 10.5em);
      max-height: calc(100vh - 10.5em);
    }

    &::-webkit-scrollbar {
      width: 0.8em;
      height: 0.8em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.4em;
    }
  }

  &__student {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    background: white;
    margin: 1em 0 1em 1.4em;
    border-radius: 0.5em;
    border: 3px solid $default-black-color;
    padding: 0 1em 0.5em 0;

    @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
      padding: 0 0.5em 0.5em 0.5em;
      margin: 0.5em 0;
      border-width: 1px;
    }

    @media (max-width: $small-width-breakpoint) {
      min-width: 33em;
      margin: 0 0 0.4em 0;
    }

    &__picture-container {
      position: relative;
      left: -0.8em;
      top: -1em;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: center;
      border: 3px solid $default-black-color;
      color: white;
      background: $default-black-color;
      border-radius: 50%;
      overflow: hidden;
      width: 5em;
      height: 5em;

      @media (max-width: $large-width-breakpoint) {
        left: -1.4em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
        display: none;
      }
    }

    &__picture-icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 4em;
      padding: 0.3em 0.3em 0;
    }

    &__name {
      font-family: 'Iceland', cursive;
      font-size: 2.8em;
      flex: 1;
      color: $default-black-color;

      @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 2.3em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.9em;
      }
    }

    &__spacer {
      display: block;
      height: 100%;
      width: 1.5em;
    }

    &__main-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;

      @media (max-width: $large-width-breakpoint) {
        margin-left: -1em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
        margin-left: 0;
      }
    }

    &__data-inputs {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      align-self: stretch;
    }

    &__input-wrapper {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      &,
      &--vertical {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        align-self: stretch;

        @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 0.85em;
        }

        &:not(:last-of-type) {
          margin: 0 0 0.6em;
        }
      }

      &--vertical {
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    &__input-label {
      color: $default-black-color;

      &,
      &--red {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.2em;
        font-weight: bold;
        min-width: 8em;
        padding: 0 0.5em 0 0;

        @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1em;
        }
      }

      &--red {
        color: $site-red-color;
      }
    }

    &__input {
      &,
      &--rounded {
        margin-top: 0 !important;

        &:not(:last-of-type) {
          margin-right: 1em;
        }

        & input {
          max-width: 6em;

          @media (max-width: $small-width-breakpoint) {
            max-width: 5em;
          }
        }

        & input,
        & select {
          font-weight: bold;
          font-size: 1.2em;
          padding: 0.3em 0.5em;
        }
      }

      & input {
        border-radius: 5px 0 0 5px;
      }

      &--rounded input,
      & select {
        border-radius: 5px;
      }
    }

    &__scale {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      align-self: stretch;

      &__group {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: stretch;
        list-style: none;
        flex: 1 1;

        &__image {
          &--1,
          &--2,
          &--3,
          &--4,
          &--5,
          &--6,
          &--7,
          &--8,
          &--9,
          &--10 {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            font-size: 3em;
            padding: 0.2em 0;
            border: 0 solid white;
            border-right-width: 1px;
            border-left-width: 1px;
            background: $default-black-color;

            @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
              font-size: 2.7em;
            }

            @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
              font-size: 2.5em;
              border-right-width: 0;
            }

            @media (max-width: $small-width-breakpoint) {
              font-size: 2em;
            }

            @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
              font-size: 1.8em;
            }
          }

          &--1 {
            color: $scale-reference-1;
          }
          &--2 {
            color: $scale-reference-2;
          }
          &--3 {
            color: $scale-reference-3;
          }
          &--4 {
            color: $scale-reference-4;
          }
          &--5 {
            color: $scale-reference-5;
          }
          &--6 {
            color: $scale-reference-6;
          }
          &--7 {
            color: $scale-reference-7;
          }
          &--8 {
            color: $scale-reference-8;
          }
          &--9 {
            color: $scale-reference-9;
          }
          &--10 {
            color: $scale-reference-10;
          }
        }

        &:first-of-type &__image {
          &--1,
          &--2,
          &--3,
          &--4,
          &--5,
          &--6,
          &--7,
          &--8,
          &--9,
          &--10 {
            border-radius: 0.1em 0 0 0;
            border-left-width: 0;
          }
        }

        &:last-of-type &__image {
          &--1,
          &--2,
          &--3,
          &--4,
          &--5,
          &--6,
          &--7,
          &--8,
          &--9,
          &--10 {
            border-radius: 0 0.1em 0 0;
            border-right-width: 0;
          }
        }

        &__options {
          display: flex;
          flex-flow: row nowrap;
          align-items: stretch;
          justify-content: stretch;
          flex: 1;
        }

        &__option {
          &--1,
          &--2,
          &--3,
          &--4,
          &--5,
          &--6,
          &--7,
          &--8,
          &--9,
          &--10 {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 0.4em 0.6em;
            outline: none;
            cursor: pointer;
            -webkit-tap-highlight-color:  transparent;
            border: none;
            user-select: none;
            font-size: 1em;
            text-decoration: none;
            min-width: 35px;
            min-height: 35px;
            font-weight: bold;
            border: 1px solid $default-black-color;
            background: $default-black-color;
            flex: 1 1;

            @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
              border-right-width: 0;
            }

            &:disabled {
              cursor: default;
              color: white;
            }
          }

          &--1 {
            color: $scale-reference-1;
            border-color: $scale-reference-1;

            &:disabled {
              background: $scale-reference-1;
            }
          }
          &--2 {
            color: $scale-reference-2;
            border-color: $scale-reference-2;

            &:disabled {
              background: $scale-reference-2;
            }
          }
          &--3 {
            color: $scale-reference-3;
            border-color: $scale-reference-3;

            &:disabled {
              background: $scale-reference-3;
            }
          }
          &--4 {
            color: $scale-reference-4;
            border-color: $scale-reference-4;

            &:disabled {
              background: $scale-reference-4;
            }
          }
          &--5 {
            color: $scale-reference-5;
            border-color: $scale-reference-5;

            &:disabled {
              background: $scale-reference-5;
            }
          }
          &--6 {
            color: $scale-reference-6;
            border-color: $scale-reference-6;

            &:disabled {
              background: $scale-reference-6;
            }
          }
          &--7 {
            color: $scale-reference-7;
            border-color: $scale-reference-7;

            &:disabled {
              background: $scale-reference-7;
            }
          }
          &--8 {
            color: $scale-reference-8;
            border-color: $scale-reference-8;

            &:disabled {
              background: $scale-reference-8;
            }
          }
          &--9 {
            color: $scale-reference-9;
            border-color: $scale-reference-9;

            &:disabled {
              background: $scale-reference-9;
            }
          }
          &--10 {
            color: $scale-reference-10;
            border-color: $scale-reference-10;

            &:disabled {
              background: $scale-reference-10;
            }
          }
        }

        &__option-number,
        &__option-text {
          &,
          &--flex {
            font-family: 'Montserrat', sans-serif;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            text-shadow: 0 0 2px $default-black-color;
          }

          &--flex {
            flex: 1;
          }
        }

        &__option-number {
          &,
          &--flex {
            font-size: 1.5em;

            @media (max-width: $small-width-breakpoint) {
              font-size: 1.2em;
            }

            @media (max-width: $very-small-width-breakpoint) {
              font-size: 1em;
            }
          }
        }

        &__option-text {
          &,
          &--flex {
            font-size: 0.9em;

            @media (max-width: $large-width-breakpoint) {
              font-size: 0.6em;
            }

            @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
              display: none;
            }
          }
        }
      }
    }

    &__action-button {
      font-size: 1.9em;
      padding: 0.6em;
      align-self: stretch;
      margin: 0.5em 0 0 0.4em;
      flex: none;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.7em;
        padding: 0.3em;
        border-radius: 3px;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.2em;
        box-shadow: none;
        margin: 0.5em 0 0 0.2em;
      }
    }
  }

  &__overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;

      &__title {
        font-family: 'Black Ops One', cursive;
        color: $default-black-color;
        font-size: 2.5em;
        text-transform: uppercase;
        text-align: justify;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.6em;
        }
      }

      &__toggle-button {
        flex: none;
        font-size: 1.2em;
        padding: 0.5em;
        box-shadow: none;
        border-radius: 3px;
        margin: 0 0 0 0.5em;
      }
    }

    &__reference-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 1.8em;
        height: 1.8em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2.8em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 2em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &__reference {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 0 solid $default-black-color;
      border-bottom-width: 1px;
      padding: 0 0 0.2em;
      min-width: 48em;

      &:not(:last-of-type) {
        margin: 0 0 0.8em;
      }

      &__date-text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        font-size: 0.8em;
        font-weight: bold;
        color: #8d8d8d;
        padding: 0.2em 0 0;
      }

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }

      &__label {
        margin: 0 2em 0 0;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.95em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.9em;
        }
      }

      &__input {
        & input,
        &--extended input {
          font-weight: bold;
          font-size: 1.1em;
          padding: 0.3em 0.2em !important;
          flex-shrink: 0;
        }

        & input {
          width: 4em;

          // @media (max-width: $small-width-breakpoint) {
          //   width: 3.5em;
          // }
        }

        &--extended input {
          width: 4.5em;

          // @media (max-width: $small-width-breakpoint) {
          //   width: 3.5em;
          // }
        }

        & .default-input__input-suffix,
        &--extended .default-input__input-suffix {
          border-radius: 0;
        }
      }

      &__input-prefix {
        &--highlighted {
          font-weight: bold;
        }
      }

      &__input-container {
        &,
        &--selected {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: center;

          &:not(:first-child) {
            margin: 0 0 0 0.5em;
          }

          &--horizontal {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }

      &__pr-input-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: center;
      }

      &__pr-text {
        color: $default-black-color;
      }

      &__arrow-icon {
        font-size: 1.4em;
        margin: 0 0.5em;
        color: $default-black-color;
      }

      &__generic-text {
        text-align: center;
        color: #7d7d7d;
        margin: 0.2em 0 0;
      }

      &__percentages-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__pr-input-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        background: #c7c7c7;
        padding: 0.4em 0.4em;
        border-radius: 3px 3px 0 0;
        border: 1px solid #c2cad8;
      }

      &__input-container--selected &__pr-input-header {
        background: #7bd053;
        border-color: #45a01a;
      }
    }
  }

  &__support-table {
    top: 100%;

    &,
    &--visible {
      position: fixed;
      will-change: transform;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      transition: top $default-animation-period;
      background: white;
      height: 100vh;
    }

    &--visible {
      top: 10em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        top: 9em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        top: 8em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        top: 5.5em;
      }

      @media (max-height: $tiny-height-breakpoint) {
        top: 4.2em;
      }
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      background: $default-black-color;
      box-shadow: 0 -4px 4px 0px #545153a8;
      border: 0 solid #e8e8e8;
      border-top-width: 4px;

      &__title-wrapper {
        position: relative;
        left: -25%;
        transform: translateY(-100%);
        will-change: transform;
        transition: $default-animation-period;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: center;
        background: $default-black-color;
        width: 30%;
        border-radius: 1em 1em 0 0;
        box-shadow: 0 -4px 4px 0px #545153a8;
        border: 4px solid #e8e8e8;
        border-bottom-width: 0;
        padding: 0.3em;
        margin: 0 0 1px;

        @media (max-width: $large-width-breakpoint) {
          width: 32%;
          left: -25%;
        }

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          width: 36%;
          left: -22%;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 41%;
          left: -20%;
        }

        @media (max-width: $small-width-breakpoint) {
          transform: translateY(0);
          left: 0;
          width: 17em;
        }

        @media (max-width: $smaller-width-breakpoint) {
          width: 16em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          width: 13em;
        }
      }

      &__title {
        font-family: 'Black Ops One', cursive;
        color: white;
        font-size: 2.2em;
        background-color: #0e0e0e;
        color: transparent;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.76);
        background-clip: text !important;
        text-transform: uppercase;
        padding: 0.2em 0;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.9em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.7em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.25em;
        }
      }
    }

    &--visible &__header__title-wrapper {
      left: 0;
      transform: translateY(0);
      border: none;
      box-shadow: none;
    }

    &__controls {
      position: absolute;
      left: 0.5em;
      top: 4px;
      transform: rotateX(180deg);
      will-change: transform;
      transform-origin: top;
      transition: transform $default-animation-period;
    }

    &--visible &__controls {
      transform: rotateX(0deg);
    }

    &__toggle-button {
      box-shadow: none;
      font-size: 2em;
      padding: 0.5em;
      border-radius: 0 0 0.3em 0.3em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.9em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $small-width-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.35em;
      }
    }

    &__content {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      overflow: auto;
      max-height: calc(100vh - 15em);
      padding: 0.5em 1em;

      @media (max-width: $medium-width-breakpoint) {
        max-height: calc(100vh - 14em);
      }
      @media (max-width: $medium-less-width-breakpoint) {
        max-height: calc(100vh - 12em);
      }
      @media (max-width: $smaller-width-breakpoint) {
        max-height: calc(100vh - 10em);
      }
      @media (max-width: $very-small-width-breakpoint) {
        max-height: calc(100vh - 9em);
      }

      @media (min-width: $tiny-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.4em);
      }
      @media (min-width: $very-small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 8em);
      }
      @media (min-width: $very-small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 8em);
      }
      @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 9.5em);
      }
      @media (min-width: $smaller-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 8em);
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 9.5em);
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 8em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
        max-height: calc(100vh - 13.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        max-height: calc(100vh - 12em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 9.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 8em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
        max-height: calc(100vh - 14em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        max-height: calc(100vh - 13em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 10em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 8.2em);
      }

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__table {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex-shrink: 0;

      &:first-of-type {
        margin-left: auto;
      }
      &:last-of-type {
        margin-right: auto;
      }
      &:not(:first-of-type) {
        margin-left: 1.5em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 0.9em;
      }
      @media (max-width: $medium-less-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.85em;
      }
      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 0.82em;
      }

      &__header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        background: #5ea9bf;
        padding: 0.4em 0.8em;
        border-radius: 5px 5px 0 0;
      }

      &__title {
        font-family: 'Black Ops One', cursive;
        font-size: 1.7em;
        color: white;
        background-color: #307b90;
        color: transparent;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.9);
        background-clip: text !important;
        text-transform: uppercase;
      }

      &__row-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border: 0 solid #307b90;
        border-width: 0 1px 1px;

        &:last-of-type {
          border-radius: 0 0 5px 5px;
        }
      }

      &__cell,
      &__column-label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: $default-black-color;
        padding: 0.2em 0.8em;
        border: 0 solid #307b90;
        min-width: 6em;
        width: 6em;

        &:first-of-type {
          border-width: 0 1px 0 0;
        }
      }

      &__column-label {
        font-family: 'Iceland', cursive;
        font-size: 1.35em;
        color: $site-red-color;
      }

      &__cell {
        font-family: 'Montserrat', sans-serif;
      }
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #eee;
    border-width: 1px 0 0;
    margin: 0.5em 0 1em;
  }

  &__no-student-text {
    font-family: 'Montserrat', sans-serif;
    color: lighten($default-black-color, 20%);
    text-align: center;
    font-size: 1.5em;
    padding: 0.8em 0;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.75em;
    }
  }

  &__small-note {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: justify;
    margin: 0.2em 0 0;
    font-size: 0.8em;
    font-weight: bold;

    @media (max-width: $smaller-width-breakpoint) {
      font-size: 0.75em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      font-size: 0.7em;
    }
  }

  &__overlay-note {
    margin: 0.2em 0 0.3em ;
    background: #dcd6c3;
    padding: 0.4em;
    border: 1px solid #b3a476;
    border-radius: 3px;
    font-size: 0.9em;

    &__text {
      color: #564f36;
      text-align: justify;

      @media (max-width: $smaller-width-breakpoint) {
        font-size: 0.8em;
      }
    }
  }
}
