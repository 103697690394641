@import '../color_variables.scss';
@import '../size_variables.scss';

.switch-unit {
  flex: 1;
  padding: 7em 3.5em 3.5em;
  min-width: 0;

  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 0.4em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $default-black-color;
    border-radius: 0.4em;
  }

  @media (max-height: $small-height-breakpoint) {
    overflow: auto;
  }

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 2.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 2.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 5em 1em 2em;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  &__instructions {
    font-family: 'Montserrat', sans-serif;
    color: $default-black-color;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 0.6em;
    font-size: 1.9em;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1.25em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.95em;
    }
  }

  &__unit-option {
    cursor: pointer;
    background: #ccc;

    &,
    &--selected {
      padding: 0;
      border: none;
    }

    &--selected {
      cursor: default;
      background: #afcc8d;
    }
  }

  &__user-action-button {
    font-family: 'Montserrat', sans-serif;
    flex: none;
    font-size: 1.2em;
    padding: 1em;
    margin: 0.5em 0;
    box-shadow: none;
    align-self: stretch;
    margin: 0;

    @media (max-width: $small-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 1.1em;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.6em;
    }

    &:last-child {
      margin: 0 0 0 0.8em;
      border-radius: 0 4px 4px 0;
    }

    &__icon {
      font-size: 1.15em;
    }
  }

  &__not-found-text {
    font-family: 'Montserrat', sans-serif;
    color: lighten($default-black-color, 20%);
    text-align: center;
    font-size: 1.5em;
    padding: 0.8em 0;

    @media (max-width: $medium-width-breakpoint) {
      font-size: 1.3em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 1em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.75em;
    }
  }

  &__unit-list {
    &::-webkit-scrollbar {
      width: 0.8em;
      height: 0.8em;

      @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
        width: 0.4em;
        height: 0.4em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.4em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.4em;
    }
  }

  &__unit-list {
    max-height: calc(100vh - 21em);

    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 19em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 13em);
    }

    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 16em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12em);
    }
  }
}
