@import '../size_variables.scss';
@import '../color_variables.scss';

$large-width-breakpoint: 1550px;
$extra-high-width-breakpoint: 1320px;
$high-width-breakpoint: 1200px;
$medium-plus-width-breakpoint: 1080px;
$medium-width-breakpoint: 970px;
$medium-less-width-breakpoint: 870px;
$small-width-breakpoint: 600px;
$very-small-width-breakpoint: 470px;
$tiny-width-breakpoint: 380px;

@keyframes loading-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loading-spin-reverse {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}

.loading-icon {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  padding: 0.5em;
  font-size: 1.1em;

  @media (max-width: $high-width-breakpoint) {
    font-size: 1em;
  }
  @media (max-width: $medium-less-width-breakpoint) {
    font-size: 0.9em;
  }
  // @media (max-width: $small-width-breakpoint) {
  //   font-size: 0.6em;
  // }
  // @media (max-width: $very-small-width-breakpoint) {
  //   font-size: 0.55em;
  // }
  // @media (max-width: $tiny-width-breakpoint) {
  //   font-size: 0.5em;
  // }

  &__text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.2em;
    max-width: 9em;
    text-align: center;
    color: #af7272;
    font-weight: bold;
  }

  &__icon {
    &-1,
    &-2 {
      position: absolute;
      transform-origin: center center;
    }

    &-1 {
      width: 7em;
      animation: loading-spin 1.8s linear infinite;
    }

    &-2 {
      width: 5em;
      opacity: 0.9;
      animation: loading-spin-reverse 1.4s linear infinite;
    }
  }
}
