@import '../color_variables.scss';
@import '../size_variables.scss';

.daily-report {
  flex: 1;
  padding: 5.5em 1.5em 0.4em;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
  min-width: 0;

  @media (max-width: $medium-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 4em 1.5em 0.4em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    padding: 3.5em 1em 1em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 3em 0.5em 1em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    padding: 3em 0.5em 0.5em;
  }

  @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
    padding: 2em 0.5em 0.5em;
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.9em
    }

    &__student-name {
      font-family: 'Iceland', cursive;
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      justify-content: flex-start;
      font-size: 1em;
      color: $default-black-color;
    }

    &__label {
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 0.2em 0 0;
    }

    &__student-picture-container {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: center;
      border: 3px solid $default-black-color;
      color: white;
      background: $default-black-color;
      border-radius: 50%;
      overflow: hidden;
      width: 3em;
      height: 3em;
    }

    &__student-picture-icon {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-size: 1.5em;
      padding: 0.3em 0.3em 0;
    }
  }

  &__confirm-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin: 3em 0 0;
  }

  &__confirm-button {
    padding: 0.2em 0;
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid $default-black-color;
    border-width: 2px 0 0;
    margin: 0.3em 0;

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      border-width: 1px 0 0;
      margin: 0.2em 0;
    }
  }

  &__graphs-container {
    margin: 0.5em 0 0;
    background: white;
    padding: 1em;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #b1b1b1;
    overflow: auto;
    max-height: calc(100vh - 11em);

    @media (max-width: $small-width-breakpoint) {
      padding: 0.5em;
    }

    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 10em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 9em);
    }
    @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 8em);
    }
    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 6.5em);
    }

    &::-webkit-scrollbar {
      width: 1.8em;
      height: 1.8em;

      @media (max-width: $medium-less-width-breakpoint) {
        width: 0.4em;
        height: 0.4em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.2em;
    }
  }

  &__graph {
    min-width: 500px;
  }

  &__graph-section {
    color: $default-black-color;
    align-self: flex-start;
    box-shadow: 0px 2px 0px $default-black-color;
    padding: 0 0 0.1em 0;
    margin: 0 0 0.8em;

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.9em;
    }

    &:not(:first-of-type) {
      margin-top: 1em;
    }

    &__text {
      text-transform: uppercase;
      font-weight: bolder;

      &:not(:first-child) {
        margin: 0 0 0 0.4em;
      }
    }
  }
}
