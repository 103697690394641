@import '../../color_variables.scss';
@import '../../size_variables.scss';

$reps-counter-color: #b7ae44;

$preference-color: #139647;
$preference-background-color: #e6ffcb;

$default-animation-period: 0.4s;

%exercise-column-side-padding {
  padding-left: 15px;
  padding-right: 12px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes red-background-blink {
  from {
    background: #c42c39;
  }

  to {
    background: #f96d6d;
  }
}

.class-board {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
  background: $background-color;
  width: 100vw;
  height: 100vh;

  &__main {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
    background: $background-color;
    margin: 0 0 7em;

    @media (max-width: $large-width-breakpoint) {
      margin: 0 0 6em;
    }

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      margin: 0 0 5.5em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 0 0 4.5em;
    }

    @media (max-width: $small-width-breakpoint) {
      margin: 0 0 4em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      margin: 0 0 3.5em;
    }

    &__nav-container {
      position: fixed;
      width: 100%;
      max-width: 60vw;
      bottom: 0;
      right: 0;
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-end;
      padding: 0 1.5em 1.5em 0;
      gap: 1.25em;
      row-gap: 0.5em;

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        padding: 0 1em 1em 0;
        gap: 1em;
        row-gap: 0.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        padding: 0 0.5em 0.8em 0;
      }
    }

    &__nav-button {
      flex: none;
      padding: 0.5em;
      font-size: 2.5em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.4em;
        border-radius: 3px;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.2em;
      }

      // &:not(:last-child) {
      //   margin: 0 0.5em 0 0;

      //   @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
      //     margin: 0 1em 0 0;
      //   }
      // }

      &__icon:not(:first-child) {
        margin: 0 0 0 0.5em;
      }

      &__text {
        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.9em;
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    background: $default-black-color;
    min-height: 2.8em;
    flex-shrink: 0;
    z-index: 2;

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      font-size: 0.85em;
    }

    @media (max-width: $small-width-breakpoint) {
      font-size: 0.78em;
    }

    &__logo-wrapper {
      position: absolute;
      padding: 0 1.6em 1em 0;
      top: 0;
      left: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      justify-content: flex-end;
      height: 8em;
      width: 9em;
      border: 4px solid $background-color;
      border-left-width: 0 !important;
      border-top-width: 0 !important;
      border-radius: 0 0 50% 0;
      background: $default-black-color;
      box-shadow: 0 1px 6px 1px lighten($default-black-color, 10%);

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        border-radius: 0 0 1em 0;
        padding: 0 0.35em 0.6em 0;
        width: 6em;
        height: 6.8em;
        border-width: 3px;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0 0.35em 0.6em 0;
        width: 5em;
        height: 5.4em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        padding: 0;
        background: none;
        border: none;
        box-shadow: none;
        align-items: flex-start;
        justify-content: flex-start;
        width: auto;
        height: auto;
        top: 0.5em;
        left: 0.2em;
      }
    }

    &__logo {
      width: 100%;
      max-width: 6em;

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        max-width: 5em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        max-width: 4em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        max-width: 3.5em;
      }
    }

    &__main-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      padding: 0.8em;
      margin: 0 0 0 10em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        margin: 0 0 0 9em;
      }

      @media (max-width: $medium-less-width-breakpoint) {
        flex-flow: column nowrap;
        align-items: stretch;
        padding: 0.8em 0.8em 0.2em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        margin: 0 0 0 6.5em;
      }

      @media (max-width: $small-width-breakpoint) {
        margin: 0 0 0 6em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        margin: 0 0 0 5em;
      }

      @media (max-width: $tiny-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        margin: 0 0 0 3.5em;
      }
    }

    &__buttons-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $medium-less-width-breakpoint) {
        margin: 0 0 0.2em;
      }
    }

    &__control-button {
      padding: 0.5em;
      font-size: 1.5em;
      box-shadow: none;
      flex: none;

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.2em;
        padding: 0.3em;
        border-radius: 3px;
      }

      &:not(:last-of-type) {
        margin: 0 1em 0 0;

        @media (max-width: $small-width-breakpoint) {
          margin: 0 0.7em 0 0;
        }
      }
    }

    &__title-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      flex: 1;
    }

    &__title {
      position: relative;
      font-family: 'Black Ops One', cursive;
      color: white;
      font-size: 2.6em;
      margin: 0 1em 0 0;
      background-color: #0e0e0e;
      color: transparent;
      text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.76);
      background-clip: text !important;
      text-transform: uppercase;
      text-align: right;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 2em;
        margin: 0 0.5em 0 0;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.4em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.2em;
      }
    }
  }

  &__overview-navigation {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    border: 1px solid $default-black-color;
    border-width: 6px 0 4px 0;
    margin: 6em 0 1.5em;
    overflow: auto;

    @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
      border-width: 3px 0 2px 0;
      margin: 5em 0 2em;
    }

    @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
      margin: 4em 0 2em;
    }

    @media (max-width: $small-width-breakpoint) {
      // overflow: auto;
      border-width: 2px 0 1px 0;
      margin: 3.5em 0 1em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      margin: 2.5em 0 1em;
    }

    @media (max-width: $tiny-width-breakpoint) {
      margin: 1.8em 0 1em;
    }

    @media (max-height: $tiny-height-breakpoint) {
      margin: 1em 0 1em;
    }

    &::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;

      @media (max-width: $smaller-width-breakpoint) { //, (max-height: $small-height-breakpoint)
        width: 0.2em;
        height: 0.2em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.2em;
    }

    &__buttons-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;

      @media (max-width: $small-width-breakpoint) {
        min-width: 680px;
      }
    }

    &__nav-button {
      padding: 0.8em 0.2em;
      font-size: 1.5em;
      box-shadow: none;
      border-radius: 0;
      min-width: 8em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.9em;
        padding: 0.6em 0.2em;
      }

      &:not(:last-of-type) {
        border: 0 solid $default-black-color;
        border-right-width: 2px;

        @media (max-width: $small-width-breakpoint) {
          border-right-width: 1px;
        }
      }

      &:disabled {
        background: #ff8585;

        & .default-menu-button__outset-text {
          background: #a75050;
          text-shadow: 0.033em 0.033em 1px rgba(255, 255, 255, 0.17);
        }
      }

      & p {
        flex-wrap: wrap;
      }
    }
  }

  &__training-group {
    margin: 0 0.5em 1.5em;
    max-height: calc(100vh - 26.5em);

    @media (max-width: $large-width-breakpoint) {
      max-height: calc(100vh - 25em);
    }
    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 21.5em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 20em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 17.5em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (max-width: $tiny-width-breakpoint) {
      max-height: calc(100vh - 14.5em);
    }

    @media (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.5em);
    }
    @media (min-width: $tiny-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.5em);
    }
    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $smaller-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.5em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 15.5em);
    }
    @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 14em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 19.7em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 14.8em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 20.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 18.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 15em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      max-height: calc(100vh - 13.4em);
    }

    &--fullscreen {
      margin: 6.5em 0.5em 1.5em;

      @media (max-width: 1525px) {
        margin-top: 4.5em;
      }
      @media (max-width: 1400px) {
        margin-top: 3.5em;
      }
      @media (max-width: $large-width-breakpoint) {
        margin-top: 1.7em;
      }
      @media (max-width: $medium-width-breakpoint) {
        margin-top: 2.8em;
      }
      @media (max-width: 980px) {
        margin-top: 1.3em;
      }
      @media (max-width: 885px) {
        margin-top: 0.9em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        margin-top: 1.1em;
      }
      @media (max-width: 750px) {
        margin-top: 0.3em;
      }
      @media (max-width: $small-width-breakpoint) {
        margin-top: 1.2em;
      }
      @media (max-width: 615px) {
        margin-top: 0.5em;
      }
      @media (max-width: $smaller-width-breakpoint) {
        margin-top: 0.8em;
      }
      @media (max-width: 460px) {
        margin-top: 0.2em;
      }
      @media (max-width: $very-small-width-breakpoint) {
        margin-top: 0.2em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        margin-top: -0.3em;
      }

      @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 1.4em;
      }
      @media (min-width: 615px) and (max-height: $small-height-breakpoint) {
        margin-top: 2.9em;
      }
      @media (min-width: 615px) and (max-height: $tiny-height-breakpoint) {
        margin-top: 1.4em;
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 4.2em;
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        margin-top: 2.3em;
      }
      @media (min-width: 750px) and (max-height: $small-height-breakpoint) {
        margin-top: 5em;
      }
      @media (min-width: 750px) and (max-height: $tiny-height-breakpoint) {
        margin-top: 2.3em;
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
        margin-top: 0.8em;
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        margin-top: 2.7em;
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 1.4em;
      }
      @media (min-width: 885px) and (max-height: $high-height-breakpoint) {
        margin-top: 1.3em;
      }
      @media (min-width: 885px) and (max-height: $medium-height-breakpoint) {
        margin-top: 3.1em;
      }
      @media (min-width: 885px) and (max-height: $small-height-breakpoint) {
        margin-top: 1.7em;
      }
      @media (min-width: 980px) and (max-height: $high-height-breakpoint) {
        margin-top: 2.7em;
      }
      @media (min-width: 980px) and (max-height: $medium-height-breakpoint) {
        margin-top: 4.6em;
      }
      @media (min-width: 980px) and (max-height: $small-height-breakpoint) {
        margin-top: 2.7em;
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
        margin-top: 4.5em;
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        margin-top: 6.3em;
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 3.8em;
      }
      @media (min-width: $large-width-breakpoint) and (max-height: $high-height-breakpoint) {
        margin-top: 7.3em;
      }
      @media (min-width: $large-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        margin-top: 9em;
      }
      @media (min-width: $large-width-breakpoint) and (max-height: $small-height-breakpoint) {
        margin-top: 5.6em;
      }
      @media (min-width: 1400px) and (max-height: $high-height-breakpoint) {
        margin-top: 8.7em;
      }
      @media (min-width: 1400px) and (max-height: $medium-height-breakpoint) {
        margin-top: 10.7em;
      }
      @media (min-width: 1400px) and (max-height: $small-height-breakpoint) {
        margin-top: 6.75em;
      }
    }

    &,
    &--fullscreen {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 4px solid $default-black-color;
      border-radius: 4px;
      overflow: auto;
      // overflow-y: hidden;
      background: linear-gradient(140deg,#797979, #bbbbbb 80%,#797979);
      flex-shrink: 0;

      &::-webkit-scrollbar {
        width: 1.5em;
        height: 1.5em;

        @media (max-width: $medium-less-width-breakpoint) { //, (max-height: $medium-height-breakpoint)
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint) { //, (max-height: $small-height-breakpoint)
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }

      &__title {
        font-family: 'Black Ops One', cursive;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding: 0.3em 0.8em 0;
        text-transform: uppercase;
        font-size: 2em;
        background-color: $default-black-color;
        color: transparent;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.54);
        background-clip: text !important;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1em;
        }
      }

      &__exercises-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__note-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0 0 0 0.8em;
        margin: 0.5em 0 0.2em;
      }

      &__note-label {
        font-family: 'Iceland', cursive;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: bold;
        margin: 0 0.4em 0 0;
        background-color: $default-black-color;
        color: transparent;
        font-size: 1.8em;
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.27);
        background-clip: text !important;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1em;
        }
      }

      &__note-text {
        font-family: 'Montserrat', sans-serif;
        background: white;
        padding: 0.3em 0.3em 0.3em 0.5em;
        color: $default-black-color;
        font-size: 1.5em;
        font-weight: bold;
        border-radius: 3px;
        flex: 1;
        white-space: pre-wrap;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.8em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.72em;
        }
      }
    }
  }

  &__training-exercise {
    &__header {
      &,
      &--centered {
        @extend %exercise-column-side-padding;
        font-family: 'Iceland', cursive;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;
        justify-content: flex-start;
        height: 1em;
        min-height: 1em;
        font-weight: bold;
        font-size: 1.8em;
        background-color: $default-black-color;
        color: transparent;
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.27);
        background-clip: text !important;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1em;
        }
      }

      &--centered {
        justify-content: center;
        text-align: center;
      }
    }

    &__reps,
    &__intensities,
    &__intensities--collapsed,
    &__names,
    &__diffculties,
    &__easier-options,
    &__references {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      text-align: left;
    }

    &__intensities-wrapper {
      &--vertical,
      &--horizontal {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
      }

      &--vertical {
        flex-flow: column nowrap;
      }

      &--horizontal {
        flex-flow: row nowrap;
      }
    }

    &__intensities-wrapper--horizontal &__intensities:not(:last-child) &__header {
      border-right: 2px solid rgba(0, 0, 0, 0.38);
    }

    &__intensities-wrapper--horizontal &__intensities:not(:first-child) &__header {
      border-left: 2px solid rgba(255, 255, 255, 0.16);
    }

    &__intensities,
    &__names,
    &__diffculties,
    &__easier-options {
      flex: 1;
    }

    &__reps-text {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to bottom, lighten($reps-counter-color, 30%), $reps-counter-color 30%, $reps-counter-color 70%, darken($reps-counter-color, 25%));
      font-weight: bold;
      color: white;
      text-shadow: 1px 1px 3px black;
      flex: 1;
      padding: 0 0.5em;
      font-size: 1.8em;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0 0.2em;
      }
    }

    &__reps-text-span {

    }

    &__reps-text,
    &__intensity-text,
    &__intensity-cycle-text,
    &__difficult-text,
    &__name-text,
    &__easier-option-text,
    &__reference-link {
      font-family: 'Montserrat', sans-serif;
      font-size: 2em;
      border: 0 solid white;
      white-space: nowrap;
      color: white;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.9em;
      }

      &:not(:last-child) {
        border-bottom-width: 1px;
      }
    }

    &__intensities-wrapper--horizontal &__intensities:not(:last-child) &__intensity-cycle-text {
      border-right: 2px solid rgba(255, 255, 255, 0.32);
    }

    &__intensity-text,
    &__intensity-cycle-text,
    &__difficult-text,
    &__name-text,
    &__easier-option-text,
    &__reference-link {
      @extend %exercise-column-side-padding;
      padding-top: 0.2em;
      padding-bottom: 0.2em;
      background: rgba(0, 0, 0, 0.58);

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0.2em 0.7em;
      }
    }

    &__reference-link {
      text-align: center;
      color: white;
      cursor: pointer;
    }

    &__first-column {
      width: 0.4em;
      min-width: 0.4em;
    }

    &__last-column {
      flex: 1;
      min-width: 0.4em;
    }

    &__not-configured {
      color: $default-black-color;
      font-weight: bold;
      background: $default-alert-color;
      border-radius: 3px;
      padding: 0.5em;
      flex: 1;
      text-align: center;
      margin: 0.5em;
    }
  }

  &__students-panel {
    top: 100vh;

    &,
    &--visible {
      position: fixed;
      will-change: transform;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      transition: top $default-animation-period;
      background: white;
      // height: 100vh;
      height: calc(100vh - 9em);

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        height: calc(100vh - 8em);
      }
      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        height: calc(100vh - 6.5em);
      }
      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        height: calc(100vh - 5em);
      }
      @media (max-height: $tiny-height-breakpoint) {
        height: calc(100vh - 4.2em);
      }
    }

    &--visible {
      top: 9em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        top: 8em;
      }
      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        top: 6.5em;
      }
      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        top: 5em;
      }
      @media (max-height: $tiny-height-breakpoint) {
        top: 4.2em;
      }
    }

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      background: $default-black-color;
      box-shadow: 0 -4px 4px 0px #545153a8;
      border: 0 solid #e8e8e8;
      border-top-width: 4px;

      &__title-wrapper {
        position: relative;
        left: -25%;
        transform: translateY(-100%);
        will-change: transform;
        transition: $default-animation-period;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: center;
        background: $default-black-color;
        width: 30%;
        border-radius: 1em 1em 0 0;
        box-shadow: 0 -4px 4px 0px #545153a8;
        border: 4px solid #e8e8e8;
        border-bottom-width: 0;
        padding: 0.3em;
        margin: 0 0 1px;

        @media (max-width: $large-width-breakpoint) {
          width: 25%;
          left: -28%;
        }

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          width: 22%;
          left: -30%;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          width: 22%;
          left: -28%;
        }

        @media (max-width: $small-width-breakpoint) {
          transform: translateY(0);
          left: 0;
        }
      }

      &__title {
        font-family: 'Black Ops One', cursive;
        color: white;
        font-size: 2.2em;
        background-color: #0e0e0e;
        color: transparent;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.76);
        background-clip: text !important;
        text-transform: uppercase;
        padding: 0.2em 0;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.9em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.7em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.25em;
        }
      }

      &__update-button,
      &__add-button {
        position: absolute;
        top: 0.32em;
        font-size: 1.8em;
        padding: 0.5em;
        flex: 0;
        box-shadow: none;

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.6em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.45em;
        }

        @media (max-width: $small-width-breakpoint) {
          border-radius: 3px;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.35em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }

      &__update-button {
        right: 3em;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          right: 2.6em;
        }
      }

      &__add-button {
        right: 0.5em;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          right: 0.3em;
        }
      }
    }

    &--visible &__header__title-wrapper {
      left: 0;
      transform: translateY(0);
      border: none;
      box-shadow: none;
    }

    &__controls {
      position: absolute;
      left: 0.5em;
      top: 4px;
      transform: rotateX(180deg);
      will-change: transform;
      transform-origin: top;
      transition: transform $default-animation-period;
    }

    &--visible &__controls {
      transform: rotateX(0deg);
    }

    &__toggle-button {
      box-shadow: none;
      font-size: 2em;
      padding: 0.5em;
      border-radius: 0 0 0.3em 0.3em;

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.9em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 1.7em;
      }

      @media (max-width: $small-width-breakpoint) {
        border-radius: 0 0 3px 3px;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.35em;
      }
    }

    &__list {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: center;
      overflow: auto;
      padding: 1.2em 0.5em 0 0.5em;
      max-height: calc(100vh - 14em);
      gap: 0 0.45em;

      @media (max-width: $small-width-breakpoint) {
        flex-flow: column nowrap;
        justify-content: flex-start;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0.7em 0.5em 0 0.5em;
      }

      @media (max-width: $medium-width-breakpoint) {
        max-height: calc(100vh - 12.5em);
      }
      @media (max-width: $medium-less-width-breakpoint) {
        max-height: calc(100vh - 10.5em);
      }
      @media (max-width: $small-width-breakpoint) {
        max-height: calc(100vh - 8.8em);
      }
      @media (max-width: $smaller-width-breakpoint) {
        max-height: calc(100vh - 8.4em);
      }

      @media (min-width: $tiny-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $very-small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 8.5em);
      }
      @media (min-width: $smaller-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 8.7em);
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
        max-height: calc(100vh - 12.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        max-height: calc(100vh - 10.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 8.5em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
        max-height: calc(100vh - 12.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        max-height: calc(100vh - 10.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 8.8em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 7.5em);
      }

      &::-webkit-scrollbar {
        width: 0.8em;
        height: 0.8em;

        @media (max-width: $medium-less-width-breakpoint) { //, (max-height: $medium-height-breakpoint)
          width: 0.4em;
          height: 0.4em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.4em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.4em;
      }
    }

    &__student {
      &,
      &--highlighted {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        background: white;
        margin: 0 0 1em 2.1em;
        border-radius: 0.5em;
        border: 2px solid $default-black-color;
        padding: 0.3em 1em 0.3em 0;
        width: 46.8%;

        @media (max-width: $large-width-breakpoint) {
          font-size: 0.9em;
        }

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          width: 45.8%;
          font-size: 0.8em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          padding: 0.3em 0.4em 0.3em 0;
          font-size: 0.75em;
        }

        @media (max-width: $small-width-breakpoint) {
          width: auto;
          border-radius: 3px;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.7em;
          margin-bottom: 0.7em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          padding: 0.3em 0.4em;
          margin-left: 0;
        }

        &.student-selected {
          box-shadow: 0 0 8px 4px #ff7979;

          & .class-board__students-panel__student__picture-container {
            // box-shadow: 0 0 3px 4px #ff7979;
          }
        }
      }

      &--highlighted {
        border-color: $preference-color;
        background: $preference-background-color;
      }

      &__picture-container {
        &,
        &--with-text,
        &--no-border {
          position: relative;
          left: -1.8em;
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          justify-content: center;
          border: 2px solid $default-black-color;
          color: white;
          background: $default-black-color;
          border-radius: 50%;
          overflow: hidden;
          width: 4.6em;
          height: 4.6em;
        }

        &--with-text {
          color: #7ebf5e;
          align-items: center;
          font-weight: bold;

          @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
            left: -0.5em;
            border-radius: 2px;
            margin: -0.3em 0;
          }
        }

        &--no-border {
          border: None;
        }

        &__text {
          font-size: 1.3em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          display: none;
        }
      }

      &__user-frame {
        &--wood,
        &--bronze,
        &--silver,
        &--gold {
          position: absolute;

          @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
            display: none;
          }
        }

        &--wood,
        &--bronze,
        &--silver,
        &--gold {
          max-width: 6.5em;
          left: -2.75em;
          top: -0.2em;
        }
      }

      &__class-count-text {
        &--wood,
        &--bronze,
        &--silver,
        &--gold {
          background: white;
          border-radius: 9px;
          padding: 0.1em 0.4em;
          font-size: 0.8em;
          font-family: 'Orbitron', sans-serif;
          font-weight: bold;
        }

        &--wood {
          border: 1.5px solid #b43449;
          color: #b43449;
        }
        &--bronze {
          border: 1.5px solid #e86d58;
          color: #e86d58;
        }
        &--silver {
          border: 1.5px solid #8c7e8a;
          color: #8c7e8a;
        }
        &--gold {
          border: 1.5px solid #db8a30;
          color: #db8a30;
        }

        &__wrapper {
          position: absolute;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          bottom: -1.3em;
          right: 1.75em;

          @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
            display: none;
          }
        }
      }

      &__flame {
        &,
        &--1,
        &--large-1,
        &--2,
        &--large-2 {
          position: absolute;
          background: radial-gradient(#ffffff 30%, #ff5a31 55%, #ff3030);
          border-radius: 50%;
          filter: url(#svg-fire-filter);
          transform-origin: center;
          transform: rotate(-135deg);

          @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
            display: none;
          }
        }

        &--1,
        &--2 {
          left: -2.2em;
          top: -0.4em;
          width: 5.5em;
          height: 6.3em;
        }
        &--large-1,
        &--large-2 {
          left: -2.77em;
          top: -0.7em;
          width: 6.6em;
          height: 7em;
        }

        &--1,
        &--large-1 {
          background: radial-gradient(#ffffff 35%, #ff5a31 60%, #ff3030);
        }
        &--2,
        &--large-2 {
          background: radial-gradient(#ffffff 35%, #31b9ff 55%, #307bff);
        }
      }

      &__picture-wrapper {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
      }

      &__avatar-image {
        object-fit: contain;
        max-width: 100%;
      }

      &__picture-icon {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 3.5em;
        padding: 0.3em 0.3em 0;

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          display: none;
        }
      }

      &__hat-image {
        position: absolute;
        left: -1.55em;
        bottom: 3.35em;
        transform-origin: bottom center;
        transform: rotate(-15deg);
        max-width: 3.5em;

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          display: none;
        }
      }

      &__sign-image {
        position: absolute;
        left: -2.5em;
        bottom: -2.2em;
        width: 7.5em;

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          display: none;
        }
      }

      &__confetti-image {
        position: absolute;
        right: -2.5em;
        width: 12em;

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          display: none;
        }
      }

      &__wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1;
        margin: 0 0 0 0.2em;
      }

      &__row-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
      }

      &__data-container {
        position: relative;
        left: -0.8em;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        align-self: stretch;

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          left: 0;
        }
      }

      &__name {
        &,
        &--d,
        &--i,
        &--s,
        &--c {
          color: $default-black-color;
          font-family: 'Iceland', cursive;
          font-size: 2.5em;
          text-shadow: 0 0 1px $default-black-color;
        }

        &--d &__first-letter {
          color: #f37778;
        }
        &--i &__first-letter {
          color: #fcd602;
        }
        &--s &__first-letter {
          color: #50bc84;
        }
        &--c &__first-letter {
          color: #35ade3;
        }

        &--first-class {
          text-decoration: underline;
        }
      }

      &__note {
        font-family: 'Montserrat', sans-serif;
        font-size: 1em;
        color: #ff8585;
        font-weight: bold;
        text-align: justify;
      }

      &__spacer {
        display: block;
        height: 100%;
        width: 1.5em;
      }

      &__actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }

      &__action-button,
      &__info-button {
        font-size: 1.6em;
        padding: 0.5em;
        box-shadow: none;

        @media (max-width: $small-width-breakpoint) {
          border-radius: 3px;
        }

        &:not(:last-of-type) {
          margin: 0 0.4em 0 0;
        }
      }

      &__info-button {
        border-radius: 50%;
        height: 2em;
        width: 2em;
      }

      &__hr-button {
        &,
        &--1,
        &--2,
        &--3,
        &--4,
        &--5 {
          font-size: 1.6em;
          padding: 0.5em;
          box-shadow: none;
          border-radius: 50%;
          height: 2em;
          width: 2em;

          @media (max-width: $small-width-breakpoint) {
            border-radius: 3px;
          }

          &:not(:last-of-type) {
            margin: 0 0.4em 0 0;
          }
        }

        &--2 {
          background: radial-gradient(#339ed2, darken(#339ed2, 4%));
        }
        &--3 {
          background: radial-gradient(#3c9855, darken(#3c9855, 4%));
        }
        &--4 {
          background: radial-gradient(#e3a715, darken(#e3a715, 4%));
        }
        &--5 {
          background: radial-gradient(#c42c39, darken(#c42c39, 4%));
        }
      }

      &__zone {
        &--alert,
        &--normal,
        &--danger,
        &--extreme-danger {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          padding: 1.5em;
          margin: 0 0.7em 0 0;
          height: 3em;
          width: 3em;
          font-size: 1.05em;
        }

        &__weekly-frequency,
        &__index {
          font-family: 'Orbitron', sans-serif;
          font-weight: bold;
          color: $default-black-color;
        }

        &__index {
          font-size: 0.9em;
        }
        &__weekly-frequency {
          font-size: 0.7em;
        }

        &--alert {
          background: radial-gradient(#ced618, #c4b716);
        }

        &--normal {
          background: radial-gradient(#7ee84d, #67b736);
        }

        &--danger {
          background: radial-gradient(#ecb6a8, #da9484);
        }

        &--extreme-danger {
          background: radial-gradient(#fd5b5b, #c71919);
        }
      }

      &__gym-buttons-container {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__gym-select-button {
        position: relative;
        left: -0.54em;
        margin: 0.2em 0 0;
        padding: 0.2em 0.8em;
        font-size: 1.8em;
        box-shadow: none;
        flex: none;
        border-radius: 5px;
        flex-shrink: 1;

        &:not(:last-child) {
          margin-right: 0.5em;
        }

        &:disabled {
          padding: 0.2em 0.5em;
          font-size: 1.6em;
        }

        @media (max-width: $small-width-breakpoint) {
          border-radius: 3px;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          left: 0;
        }
      }

      &__training-period-progress {
        font-size: 1.8em;
        position: relative;
        left: -0.54em;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin: 0.2em 0.3em 0 0;
        padding: 0 0.3em;
        border: 0 solid $default-black-color;
        background: white;
        border-width: 1px 2px;
        border-radius: 1em;
        height: 1em;
        align-self: center;

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0.2em 0.2em 0 0;
        }

        &__text {
          font-family: 'Orbitron', sans-serif;
          font-weight: bold;
          font-size: 0.5em;
          color: $default-black-color;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          left: 0;
        }
      }

      &__physical-evaluation-icon {
        font-size: 0.6em;
        color: #0ca1f9;
        margin: 0 0.15em 0 0;
      }

      &__workout-data {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        // border-radius: 3px;
        // border: 1px solid $default-black-color;
        margin: 0.2em 0 0;

        &__indicators {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: flex-start;
        }

        &__title {
          font-family: 'Iceland', cursive;
          font-size: 1.5em;
          color: white;
          background: $default-black-color;
          padding: 0.3em;
          text-transform: uppercase;
          margin: 0 0.3em 0 0;
        }

        &__text {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          color: $default-black-color;
          border-radius: 3px;
          border: 1px solid $default-black-color;
          font-size: 1.2em;
          margin: 0 0 0.2em;

          &:not(:last-of-type) {
            margin-right: 0.4em;
          }
        }

        &__label {
          font-family: 'Iceland', cursive;
          color: white;
          background: $default-black-color;
          padding: 0.3em;
        }

        &__value {
          font-family: 'Orbitron', sans-serif;
          font-size: 1.2em;
          padding: 0 0.3em;

          // color: white;
          // background: $default-black-color;
          // padding: 0.3em;
          // border-radius: 2px;
        }
      }
    }

    &__line-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.5em 1.5em 0.3em;
    }

    &__current-time {
      &,
      &--green,
      &--yellow {
        font-family: 'Orbitron', sans-serif;
        font-weight: bold;
        color: $site-red-color;
        border: 1px solid $site-red-color;
        border-radius: 3px;
        padding: 0.2em 0.5em;
        text-align: center;
        width: 4.5em;
        font-size: 1.3em;
      }

      &--green {
        color: #3c9855;
        border-color: #3c9855;
      }
      &--yellow {
        color: #e3a715;
        border-color: #e3a715;
      }
    }

    &__order-control {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.9em;
        justify-content: center;
      }

      &__label {
        font-family: 'Iceland', cursive;
        color: $default-black-color;
        font-size: 1.7em;
        text-transform: uppercase;
        margin: 0 0.3em 0 0;

        @media (max-width: $very-small-width-breakpoint) {
          display: none;
        }
      }

      &__buttons-group {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }

      &__button {
        font-size: 1.3em;
        padding: 0.5em 0;
        box-shadow: none;
        border-radius: 0;
        width: 5em;

        &:first-of-type {
          border-radius: 3px 0 0 3px;
        }
        &:last-of-type {
          border-radius: 0 3px 3px 0;
        }
        &:not(:last-of-type) {
          border: 0 solid white;
          border-right-width: 2px;
        }

        &:disabled {
          & .default-menu-button__outset-text {
            text-shadow: -0.055em -0.055em 1px rgba(255, 255, 255, 0.85);
          }
        }
      }
    }
  }

  &__clock-container {
    margin: 0.5em 0;

    &,
    &--hidden {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      padding: 0 1em 0 0;

      @media (max-width: $small-width-breakpoint) {
        padding: 0 0.55em 0;
      }
    }

    &--hidden {
      margin: 2.5em 0;

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        margin: 1.9em 0;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        margin: 1.2em 0;
      }
    }
  }

  &__clock-container--hidden &__clock {
    display: none;
  }

  &__clock {
    &,
    &--expanded,
    &--super-expanded {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: center;
      background: $default-black-color;
      border-radius: 0.5em;
      padding: 0.2em;
      will-change: transform;
      transform-origin: top right;
      transition: $default-animation-period;

      @media (max-width: $large-width-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.7em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 0.54em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.45em;
        border-radius: 3px;
        padding: 1px;
      }
    }

    &--expanded {
      position: relative;
      // transform: scale(1.4, 1.4);
      //
      // @media (max-width: $tiny-width-breakpoint) {
      //   transform: scale(1.25, 1.25);
      // }
    }

    &--super-expanded {
      position: relative;
      right: -1em;
      // transform: scale(2.2, 2.2);

      @media (max-width: $small-width-breakpoint) {
        right: -0.55em;
      }

      // @media (max-width: 1525px) {
      //   transform: scale(2, 2);
      // }
      // @media (max-width: 1400px) {
      //   transform: scale(1.88, 1.88);
      // }
      // @media (max-width: $large-width-breakpoint) {
      //   transform: scale(1.78, 1.78);
      // }
      // @media (max-width: $medium-width-breakpoint) {
      //   transform: scale(2, 2);
      // }
      // @media (max-width: 980px) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (max-width: 885px) {
      //   transform: scale(1.74, 1.74);
      // }
      // @media (max-width: $medium-less-width-breakpoint) {
      //   transform: scale(1.95, 1.95);
      // }
      // @media (max-width: 750px) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (max-width: $small-width-breakpoint) {
      //   transform: scale(2.2, 2.2);
      // }
      // @media (max-width: 615px) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (max-width: $smaller-width-breakpoint) {
      //   transform: scale(2.3, 2.3);
      // }
      // @media (max-width: 460px) {
      //   transform: scale(2.1, 2.1);
      // }
      // @media (max-width: $very-small-width-breakpoint) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (max-width: $tiny-width-breakpoint) {
      //   transform: scale(1.6, 1.6);
      // }
      //
      // @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      //   transform: scale(2.5, 2.5);
      // }
      // @media (min-width: 615px) and (max-height: $small-height-breakpoint) {
      //   transform: scale(3, 3);
      // }
      // @media (min-width: 615px) and (max-height: $tiny-height-breakpoint) {
      //   transform: scale(2.5, 2.5);
      // }
      // @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      //   transform: scale(3, 3);
      // }
      // @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
      //   transform: scale(2.5, 2.5);
      // }
      // @media (min-width: 750px) and (max-height: $small-height-breakpoint) {
      //   transform: scale(3.2, 3.2);
      // }
      // @media (min-width: 750px) and (max-height: $tiny-height-breakpoint) {
      //   transform: scale(2.5, 2.5);
      // }
      // @media (min-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
      //   transform: scale(1.74, 1.74);
      // }
      // @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(2.25, 2.25);
      // }
      // @media (min-width: 885px) and (max-height: $high-height-breakpoint) {
      //   transform: scale(1.8, 1.8);
      // }
      // @media (min-width: 885px) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(2.32, 2.32);
      // }
      // @media (min-width: 980px) and (max-height: $high-height-breakpoint) {
      //   transform: scale(2, 2);
      // }
      // @media (min-width: 980px) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(2.6, 2.6);
      // }
      // @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      //   transform: scale(2.25, 2.25);
      // }
      // @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(2.9, 2.9);
      // }
      // @media (min-width: $large-width-breakpoint) and (max-height: $high-height-breakpoint) {
      //   transform: scale(2.65, 2.65);
      // }
      // @media (min-width: $large-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(3.4, 3.4);
      // }
      // @media (min-width: 1400px) and (max-height: $high-height-breakpoint) {
      //   transform: scale(2.85, 2.85);
      // }
      // @media (min-width: 1400px) and (max-height: $medium-height-breakpoint) {
      //   transform: scale(3.7, 3.7);
      // }
    }

    &__time-wrapper,
    &__lap-wrapper {
      // background: radial-gradient(#ff8585, #d05353);

      &,
      &--red,
      &--green,
      &--yellow,
      &--blue {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 0.5em 2.2em;

        background: black;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.88em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.65em;
          padding: 0.5em 1.5em;
        }
      }

      // &--red {
      //   background: radial-gradient(#ff8585, #d05353);
      // }
      //
      // &--green {
      //   background: radial-gradient(#7ebf5e, #62ab3f);
      // }
      //
      // &--yellow {
      //   background: radial-gradient(#e0dc4b, #989517);
      // }
      //
      // &--blue {
      //   background: radial-gradient(#5ea9bf, #3996b1);
      // }
    }

    &__time-wrapper {
      &,
      &--red,
      &--green,
      &--yellow,
      &--blue {
        border-radius: 0 0.5em 0.5em 0;
        cursor: pointer;
      }
    }

    &--super-expanded .training_clock__time-wrapper,
    &--super-expanded &__time-wrapper {
      &,
      &--red,
      &--green,
      &--yellow,
      &--blue {
        cursor: default;
      }
    }

    &__lap-wrapper {
      &,
      &--red,
      &--green,
      &--blue,
      &--yellow {
        margin: 0 1em 0 0;
      }
    }

    &--super-expanded &__time-wrapper,
    &--super-expanded &__lap-wrapper {
      &,
      &--red,
      &--green,
      &--yellow,
      &--blue {
        border-radius: 0.5em;
      }
    }

    &__time-wrapper &__compress-button,
    &__time-wrapper--red &__compress-button,
    &__time-wrapper &__time,
    &__time-wrapper--red &__time,
    &__lap-wrapper &__lap,
    &__lap-wrapper--red &__lap {
      &,
      &--centered {
        // background-color: #af2626;

        color: #d62323;
      }
    }

    &__time-wrapper--green &__compress-button,
    &__time-wrapper--green &__time,
    &__lap-wrapper--green &__lap {
      &,
      &--centered {
        // background-color: #3c791d;

        color: #89f155;
      }
    }

    &__time-wrapper--yellow &__compress-button,
    &__time-wrapper--yellow &__time,
    &__lap-wrapper--yellow &__lap {
      &,
      &--centered {
        // background-color: #7d7b1d;

        color: #dad61e;
      }
    }

    &__time-wrapper--blue &__compress-button,
    &__time-wrapper--blue &__time,
    &__lap-wrapper--blue &__lap {
      &,
      &--centered {
        // background-color: #33798e;

        color: #1a9fc7;
      }
    }

    &__time,
    &__lap {
      &,
      &--centered {
        font-family: 'Orbitron', sans-serif;
        font-size: 7em;
        color: transparent;
        // text-shadow: -0.055em -0.055em 1px rgba(255, 255, 255, 0.85);
        // background-clip: text !important;
        text-transform: uppercase;
        font-weight: bold;
      }

      &--centered {
        text-align: center;
      }
    }

    &__lap {
      @media (max-width: $tiny-width-breakpoint) {
        text-align: center;
      }
    }

    &__time {
      &,
      &--centered {
        width: 3.7em;
      }
    }

    &__controls {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0 2.2em;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        padding: 0 1em;
      }
    }

    &__control-button {
      padding: 0.3em;
      box-shadow: none;
      font-size: 3.8em;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 2.5em;
      }

      &:not(:last-of-type) {
        margin: 0 0.7em 0 0;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          margin: 0 0.4em 0 0;
        }
      }
    }

    &__compress-button {
      font-size: 1.2em;
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      outline: none;
      -webkit-tap-highlight-color:  transparent;
      user-select: none;
      text-decoration: none;
      min-width: 35px;
      min-height: 35px;
      border-radius: 0.5em;
      cursor: pointer;
    }

    & &__compress-button,
    &--expanded &__compress-button {
      display: none;
    }
  }

  &__overlay,
  &__exercise-video-overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 1.5em 0.8em;
    min-width: 90vw;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;

      &__title {
        font-family: 'Black Ops One', cursive;
        color: $default-black-color;
        font-size: 2.5em;
        text-transform: uppercase;
        text-align: justify;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.3em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.6em;
        }
      }

      &__toggle-button {
        flex: none;
        font-size: 1.2em;
        padding: 0.5em;
        box-shadow: none;
        border-radius: 3px;
        margin: 0 0 0 0.5em;
      }
    }

    &__reference-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      padding: 1em;
      max-height: 70vh;
      overflow: auto;

      @media (max-width: $small-width-breakpoint) {
        padding: 0.4em;
      }

      @media (max-height: $high-height-breakpoint) {
        max-height: 55vh;
      }

      @media (max-height: $small-height-breakpoint) {
        max-height: 40vh;
      }

      &::-webkit-scrollbar {
        width: 1.5em;
        height: 1.5em;

        @media (max-width: $medium-less-width-breakpoint) { //, (max-height: $medium-height-breakpoint)
          width: 0.4em;
          height: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint) { //, (max-height: $small-height-breakpoint)
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }
    }

    &__action-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-end;
      align-self: stretch;
      margin: 1em 0 0;
    }

    &__action-button {
      font-size: 2.8em;
      padding: 0.2em;

      @media (max-width: $large-width-breakpoint) {
        font-size: 2.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 2.2em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-less-width-breakpoint) {
        font-size: 2em;
        border-radius: 3px;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
        font-size: 1.8em;
      }

      &:not(:last-of-type) {
        margin: 0 0.6em 0 0;
      }
    }

    &__reference {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 0 solid $default-black-color;
      border-bottom-width: 1px;
      padding: 0 0 0.2em;
      min-width: 48em;

      &:not(:last-of-type) {
        margin: 0 0 0.8em;
      }

      &__date-text {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-end;
        font-size: 0.8em;
        font-weight: bold;
        color: #8d8d8d;
        padding: 0.2em 0 0;
      }

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }

      &__label {
        margin: 0 0.5em 0 0;

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.95em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.9em;
        }
      }

      &__input {
        &,
        &--extended {
          border: 0 solid white;
          border-width: 0 1px;
        }

        & input,
        &--extended input {
          font-weight: bold;
          font-size: 1.1em;
          padding: 0.3em 0.2em !important;
          flex-shrink: 0;
          border: 0 solid white;
        }

        & input {
          width: 4em;

          // @media (max-width: $small-width-breakpoint) {
          //   width: 3.5em;
          // }
        }

        &--extended input {
          width: 4.5em;

          // @media (max-width: $small-width-breakpoint) {
          //   width: 3.5em;
          // }
        }

        & .default-input__input-suffix,
        &--extended .default-input__input-suffix {
          border-radius: 0;
          border: 0 solid white;
        }
      }

      &__input-prefix {
        &--highlighted {
          font-weight: bold;
        }
      }

      &__input-container {
        &,
        &--selected {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: center;
          flex: 1;

          // &:not(:first-child) {
          //   margin: 0 0 0 0.5em;
          // }

          &--horizontal {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }

      &__pr-input-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: center;
      }

      &__pr-text {
        color: $default-black-color;
      }

      &__arrow-icon {
        font-size: 1.4em;
        margin: 0 0.5em;
        color: $default-black-color;
      }

      &__generic-text {
        text-align: center;
        color: #7d7d7d;
        margin: 0.2em 0 0;
      }

      &__input-group-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;

        &:first-child:last-child &__title-wrapper--resistance,
        &:first-child:last-child &__title-wrapper--hypertrophy,
        &:first-child:last-child &__title-wrapper--strength {
           border-radius: 3px 3px 0 0;
        }
        &:first-child:not(:last-child) &__title-wrapper--resistance,
        &:first-child:not(:last-child) &__title-wrapper--hypertrophy,
        &:first-child:not(:last-child) &__title-wrapper--strength {
          border-radius: 3px 0 0 0;
        }
        &:not(:first-child):last-child &__title-wrapper--resistance,
        &:not(:first-child):last-child &__title-wrapper--hypertrophy,
        &:not(:first-child):last-child &__title-wrapper--strength {
          border-radius: 0 3px 0 0;
        }

        &__title-wrapper {
          &,
          &--resistance,
          &--hypertrophy,
          &--strength {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            font-weight: bold;
            text-shadow: 1px 1px white;
            padding: 0.3em;
            border: 1px solid white;
            border-bottom-width: 0;
          }

          &--resistance {
            background: linear-gradient(348deg, #b7b34c, #fff605, #b7b34c);
            color: #3c3a02;
          }
          &--hypertrophy {
            background: linear-gradient(348deg, #a78425, #ffbc00, #a78425);
            color: #584203;
          }
          &--strength {
            background: linear-gradient(348deg, #ad5b22, #ff6a00, #ad5b22);
            color: #6a2f06;
          }
        }

        &__title {
          font-size: 0.9em;
        }
      }

      &__input-groups-wrapper,
      &__percentages-wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
      }

      &__pr-input-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        background: #c7c7c7;
        padding: 0.4em 0.4em;
        // border-radius: 3px 3px 0 0;
        border: 0 solid white; //#c2cad8
        border-width: 0 1px;
      }

      &__input-container--selected &__pr-input-header {
        background: #7bd053;
        border-color: #45a01a;
      }
    }

    &__generic-note {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      color: $site-red-color;
      text-align: justify;
      border: 1px solid $site-red-color;
      border-radius: 3px;
      margin: 0 0 0.5em;
      align-self: center;
      padding: 0 0.4em 0 0;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.9em;
      }

      &__label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 0.2em 0.2em 0.2em 0.4em;
        background: $site-red-color;
        color: white;
        border-radius: 1px 0 0 1px;
        margin: 0 0.4em 0 0;
        align-self: stretch;
      }
    }
  }

  &__overlay__hr-device-option {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background: #ccc;
    padding: 0;
    border: none;
    border-radius: 0.5em;


    &:not(:last-of-type) {
      margin: 0 0 0.8em;
    }

    @media (max-width: $medium-width-breakpoint) {
      font-size: 0.9em;
    }

    @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
      font-size: 0.79em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 0.75em;
      border-radius: 4px;
    }

    @media (max-width: $very-small-width-breakpoint) {
      font-size: 0.65em;
    }

    &__header {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      flex: 1;
      margin: 0 0 0 1em;

      &__title,
      &__sub-title {
        font-family: 'Iceland', cursive;
      }

      &__title {
        color: $default-black-color;
        font-size: 2em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1.6em;
        }
      }

      &__sub-title {
        color: #c53233;
        font-size: 1.4em;

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
          font-size: 1em;
        }
      }
    }

    &__select-button {
      font-family: 'Montserrat', sans-serif;
      flex: none;
      font-size: 1.2em;
      padding: 1em;
      margin: 0.5em 0;
      box-shadow: none;
      align-self: stretch;
      margin: 0;

      @media (max-width: $small-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 1.1em;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.6em;
      }

      &:last-child {
        margin: 0 0 0 0.8em;
        border-radius: 0 4px 4px 0;
      }

      &__icon {
        font-size: 1.15em;
        margin: 0 0.5em 0 0;
      }
    }
  }

  &__exercise-video-overlay {
    width: 100%;
    height: 90%;
    margin: 0;
    align-self: center;

    &__reference-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      background: center no-repeat url(../../images/preloader.gif), white;
      padding: 0;
      margin: 0;
      max-height: none;
    }
  }

  &__horizontal-rule {
    width: 100%;
    border: 0 solid #eee;
    border-width: 1px 0 0;
    margin: 0.5em 0 1em;
  }

  &__class-time-container {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__class-time-button {
    font-size: 2em;
    border-radius: 3px;
    padding: 0.2em 0.4em;
    margin: 0 0.3em 0.5em;
    min-width: 5em;
  }

  &__loading-icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    will-change: transform;
    animation: 1.5s infinite linear spin;
    font-size: 1.2em;
  }

  &__hr-view {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 !important;
    border-radius: 0;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      background: $default-black-color;
      flex-shrink: 0;
      padding: 0.3em 1em;

      &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        & .class-board__unified-group-view__clock-control {
          font-size: 0.45em;
        }
      }

      &__title {
        position: relative;
        font-family: 'Black Ops One', cursive;
        color: white;
        font-size: 2.6em;
        background-color: #0e0e0e;
        color: transparent;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.76);
        background-clip: text !important;
        text-transform: uppercase;
        text-align: right;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.2em;
        }

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.7em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }
    }

    &__main {
      // flex: 1;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      height: 100%;
      min-height: 0;
    }

    &__clock-section {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-around;
      background: #3a3839;
      min-width: 0;
    }

    &__sub-main-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;
      flex: 1;
      min-height: 0;
    }

    &__views-wrapper {
      // display: flex;
      // flex-flow: row wrap;
      // align-items: flex-start;
      // justify-content: flex-start;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(17em, 1fr));
      // grid-auto-columns: minmax(max-content, 16em);
      grid-auto-flow: row;
      grid-auto-rows: minmax(max-content, 16em);
      gap: 2px;
      background: $default-black-color;
      overflow: auto;
      flex: 1;

      @media (max-width: 1450px) {
        grid-template-columns: repeat(auto-fill, minmax(15.3em, 1fr));
        grid-auto-rows: minmax(max-content, 14.4em);
      }
      @media (max-width: $large-width-breakpoint) {
        grid-template-columns: repeat(auto-fill, minmax(13.6em, 1fr));
        grid-auto-rows: minmax(max-content, 12.8em);
      }
      @media (max-width: $medium-less-width-breakpoint) {
        grid-template-columns: repeat(auto-fill, minmax(11.9em, 1fr));
        grid-auto-rows: minmax(max-content, 11.2em);
      }
      @media (max-width: $small-width-breakpoint) {
        grid-template-columns: repeat(auto-fill, minmax(10.2em, 1fr));
        grid-auto-rows: minmax(max-content, 9.6em);
      }

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;

        @media (max-width: $smaller-width-breakpoint) { //, (max-height: $small-height-breakpoint)
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $site-red-color;
        border-radius: 0;
      }
    }

    &__hr-range {
      display: flex;
      flex-flow: column nowrap;
      align-items:center;
      justify-content: space-between;
      font-family: 'Orbitron', sans-serif;
      // color: #929292;
      color: white;
      font-size: 0.65em;
      font-weight: normal;
      // background: linear-gradient(to top, #35ade399, #d03b3b99);
      background: linear-gradient(to top, #d2d2d2, #d2d2d2 60%, #339ed2 60%, #339ed2 70%, #3c9855 70%, #3c9855 80%, #e3a715 80%, #e3a715 90%, #c42c39 90%, #c42c39);
      border-radius: 0;
      // border: 0 solid black;
      // border-left-width: 1px;
      padding: 0 0.3em;
      height: 100%;
      overflow: hidden;

      &__min,
      &__max {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: 0 0.2em;
        background: #3a3839de;
      }

      &__min {
        justify-content: flex-start;
      }

      &__max {
        justify-content: flex-end;
      }

      &__text {
        position: relative;
        font-size: 0.85em;
        background: #3a38395e;
        padding: 0.06em 0.4em;
        border-radius: 1em;
      }

      &__min &__text {
        bottom: 1.5em;
      }

      &__max &__text {
        top: 1.5em;
      }
    }

    &__view {
      &,
      &--1,
      &--2,
      &--3,
      &--4,
      &--5 {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.1em 0;
        // border: 2px solid $default-black-color;
        border: none;
        // min-width: 27em;
        // max-width: 38em;
        // max-height: 20em;
        flex: 1;
        // background: #929292;
        background: white;
        position: relative;
        // min-height: 0;

        @media (max-width: 1450px) {
          font-size: 0.9em;
        }
        @media (max-width: $large-width-breakpoint) {
          font-size: 0.8em;
          // max-width: 45em;
        }
        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.7em;
        }
        @media (max-width: $small-width-breakpoint) {
          font-size: 0.6em;
          // max-width: none;
        }
      }

      &,
      &--1 {
        // background: #929292;
        background: white;
      }

      &--2 {
        background: #339ed2;
      }
      &--3 {
        background: #3c9855;
      }
      &--4 {
        background: #e3a715;
      }
      &--5 {
        background: #c42c39;
        animation: 0.6s infinite alternate linear red-background-blink;
      }

      &__student-name {
        &,
        &--d,
        &--i,
        &--s,
        &--c {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          text-align: center;
          align-self: flex-start;
          width: 100%;
          padding: 0 0 0 2em;
          color: $default-black-color;
          font-family: 'Iceland', cursive;
          font-size: 2.1em;
          // text-shadow: 0 0 1px $default-black-color;
          text-shadow: 1px 1px 1px white;
          // height: 1em;
          overflow: hidden;
          word-break: break-all;
        }

        &--d &__first-letter {
          color: #f37778;
        }
        &--i &__first-letter {
          color: #fcd602;
        }
        &--s &__first-letter {
          color: #50bc84;
        }
        &--c &__first-letter {
          color: #35ade3;
        }
      }

      &__main-container {
        position: relative;
        // width: 55%;
        // min-width: 22em;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        font-size: 0.78em;
      }

      &__hr-range {
        position: absolute;
        left: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-between;
        font-family: 'Orbitron', sans-serif;
        color: #929292;
        color: white;
        font-size: 1.1em;
        background: linear-gradient(to top, #35ade399, #d03b3b99);
        border-radius: 5px;
        padding: 0.4em 0.6em;
        height: 5em;
      }

      &__range-indicator {
        position: absolute;
        right: -4.2em;
        display: flex;
        flex-flow: row nowrap;
        align-content: center;
        justify-content: center;

        &__text {
          &,
          &--up,
          &--down,
          &--ok {
            font-size: 3.9em;
          }

          &--up {
            color: #35ade3;
            animation: 1.5s infinite alternate cubic-bezier(0.79, 0.01, 1, 1) blink;
          }
          &--down {
            color: #d03b3b;
            animation: 1.5s infinite alternate cubic-bezier(0.79, 0.01, 1, 1) blink;
          }
          &--ok {
            color: #50bc84;
          }
        }
      }

      &--2 &__range-indicator__text--up,
      &--2 &__range-indicator__text--down,
      &--2 &__range-indicator__text--ok ,
      &--3 &__range-indicator__text--up,
      &--3 &__range-indicator__text--down,
      &--3 &__range-indicator__text--ok ,
      &--4 &__range-indicator__text--up,
      &--4 &__range-indicator__text--down,
      &--4 &__range-indicator__text--ok ,
      &--5 &__range-indicator__text--up,
      &--5 &__range-indicator__text--down,
      &--5 &__range-indicator__text--ok {
        color: white;
      }

      &__hr-container {
        &,
        &--1,
        &--2,
        &--3,
        &--4,
        &--5 {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
        }
      }

      &__row-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        margin: 0;
        padding: 0 0.8em;
        font-size: 0.7em;
      }

      &__hr-text {
        font-size: 2.2em;
        color: #929292;
      }

      &__hr-icon {
        // position: absolute;
        // font-size: 14em;
        // top: 0.26em;
        font-size: 1.7em;
        margin: 0 0 0 0.2em;
        color: #929292;
      }

      &--2 &__hr-text,
      &--2 &__hr-icon,
      &--3 &__hr-text,
      &--3 &__hr-icon,
      &--4 &__hr-text,
      &--4 &__hr-icon,
      &--5 &__hr-text,
      &--5 &__hr-icon {
        color: white;
      }

      &__hr-text,
      &__energy {
        font-family: 'Orbitron', sans-serif;
        font-weight: bold;
      }

      &__percentage-text {
        &,
        &--1,
        &--2,
        &--3,
        &--4,
        &--5 {
          font-family: 'Orbitron', sans-serif;
          font-weight: bold;
          font-size: 4.4em;
          color: #929292;
        }

        &,
        &--1 {
          color: #929292;
        }

        &--2,
        &--3,
        &--4,
        &--5 {
          color: white;
        }
        // &--2 {
        //   color: #339ed2;
        // }
        // &--3 {
        //   color: #3c9855;
        // }
        // &--4 {
        //   color: #e3a715;
        // }
        // &--5 {
        //   color: #c42c39;
        // }
      }

      // &__hr-container--2 &__hr-icon,
      // &__hr-container--2 &__hr-text {
      //   color: #339ed2;
      // }
      // &__hr-container--3 &__hr-icon,
      // &__hr-container--3 &__hr-text {
      //   color: #3c9855;
      // }
      // &__hr-container--4 &__hr-icon,
      // &__hr-container--4 &__hr-text {
      //   color: #e3a715;
      // }
      // &__hr-container--5 &__hr-icon,
      // &__hr-container--5 &__hr-text {
      //   color: #c42c39;
      // }

      &__energy {
        font-size: 2.2em;
        color: #d03b3b;
        text-shadow: 1px 1px 1px white;
      }

      &--5 &__energy {
        color: white;
        text-shadow: none;
      }

      &__picture-container {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        border: 0 solid $default-black-color;
        border-right-width: 2px;
        border-bottom-width: 2px;
        border-bottom-right-radius: 25px;
        overflow: hidden;
      }

      &--2 &__picture-container,
      &--3 &__picture-container,
      &--4 &__picture-container,
      &--5 &__picture-container {
        border-color: white;
      }

      &__picture {
        width: 4em;
        height: 4em;
      }

      &__picture-icon {
        padding: 0.15em;
        font-size: 3.04em;
        color: white;
        background: $default-black-color;
      }
    }

    &__clock {
      border-radius: 0;
      flex: 1;
      min-width: 50em;
      order: 2;
      flex-shrink: 0;

      @media (max-width: 860px) {
        order: 1;
        min-width: 100%;
      }

      & .training_clock__lap-wrapper {
        &,
        &--red,
        &--green,
        &--yellow,
        &--blue {
          flex: 1;
        }
      }

      & .training_clock__time-wrapper {
        &,
        &--red,
        &--green,
        &--yellow,
        &--blue {
          flex: 1;
          cursor: default;
        }
      }
    }

    & .class-board__unified-group-view__clock-container {
      border-radius: 0;
    }

    & .class-board__unified-group-view__clock__title {
      font-size: 1em;
      white-space: nowrap;
      overflow: hidden;
      padding: 0.1em 0.2em !important;
      align-self: center;
    }

    &__unified-view-clock {
      border-radius: 0;
      padding: 0;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: stretch;

      & .training_clock__lap-wrapper {
        &,
        &--red,
        &--green,
        &--yellow,
        &--blue {
          margin: 0;
          padding: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 0.4em;
        }
      }

      & .training_clock__time-wrapper {
        &,
        &--red,
        &--green,
        &--yellow,
        &--blue {
          margin: 0;
          padding: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }
    }
  }

  &__coach-selector {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 !important;
    border-radius: 0;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      background: $default-black-color;
      flex-shrink: 0;
      padding: 0.3em 1em;

      &__title {
        position: relative;
        font-family: 'Black Ops One', cursive;
        color: white;
        font-size: 2.6em;
        background-color: #0e0e0e;
        color: transparent;
        text-shadow: 2px 2px 1px rgba(255, 255, 255, 0.76);
        background-clip: text !important;
        text-transform: uppercase;
        text-align: right;

        @media (max-width: $large-width-breakpoint) {
          font-size: 2.2em;
        }

        @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 2em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.7em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
          font-size: 1.2em;
        }
      }
    }

    &__main {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      overflow: auto;
      padding: 1em;

      &::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;

        @media (max-width: $smaller-width-breakpoint) { //, (max-height: $small-height-breakpoint)
          width: 0.2em;
          height: 0.2em;
        }
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
        border-radius: 0.2em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $default-black-color;
        border-radius: 0.2em;
      }
    }

    &__input-wrapper {
      font-size: 0.85em;
    }

    &__instructions {
      font-family: 'Montserrat', sans-serif;
      color: $default-black-color;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 0 0.4em;
      font-size: 1.6em;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.5em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1.25em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.95em;
      }

      &--underline {
        text-decoration: underline;
      }
      &--highlight {
        color: $site-red-color;
      }
    }

    &__not-found-text {
      font-family: 'Montserrat', sans-serif;
      color: lighten($default-black-color, 20%);
      text-align: center;
      font-size: 1.5em;
      padding: 0.8em 0;

      @media (max-width: $medium-width-breakpoint) {
        font-size: 1.3em;
      }

      @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 1em;
      }

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.85em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
        font-size: 0.75em;
      }
    }

    &__user-action-button {
      font-family: 'Montserrat', sans-serif;
      flex: none;
      font-size: 1em;
      padding: 1em;
      margin: 0.5em 0;

      @media (max-width: $small-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.9em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        box-shadow: none;
        border-radius: 4px;
        align-self: stretch;
        margin: 0;
      }

      @media (max-width: $very-small-width-breakpoint) {
        padding: 0.6em;
      }
    }

    &__coach-list {
      overflow: visible;
      font-size: 0.8em;
    }

    &__horizontal-rule {
      width: 100%;
      border: 0 solid #3a3839;
      border-width: 1px 0 0;
      margin: 0.5em 0 1em;
    }

    &__selected-coach {
      font-family: 'Iceland', cursive;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      color: $default-black-color;
      margin: 0 0 0.5em;
      font-size: 0.8em;

      &__bullet-icon {
        font-size: 0.7em;
        margin: 0 0.4em 0 0;
      }

      &__name {
        font-size: 2.5em;
        margin: 0 0.5em 0 0;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 2.2em;
        }

        @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.8em;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 1.6em;
        }

        @media (max-width: $very-small-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.4em;
        }
      }
    }
  }

  &__personal-training {
    &__period-overview {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      background: $default-black-color;
      border-radius: 5px;
      border: 1px solid $default-black-color;
      border-top-width: 0;
      margin: 5em 1em 1.5em;
      max-height: calc(100vh - 16em);
      // overflow: auto;

      @media (max-width: $medium-width-breakpoint) {
        max-height: calc(100vh - 14em);
        margin: 4.5em 1em 1.5em;
      }
      @media (max-width: $medium-less-width-breakpoint) {
        max-height: calc(100vh - 12em);
        margin: 3em 0.6em 1.5em;
      }
      @media (max-width: $small-width-breakpoint) {
        max-height: calc(100vh - 11em);
      }
      @media (max-width: $smaller-width-breakpoint) {
        max-height: calc(100vh - 9.3em);
        border-radius: 3px;
        margin: 1.8em 0.4em 1.5em;
      }
      @media (max-width: $tiny-width-breakpoint) {
        max-height: calc(100vh - 8em);
        margin: 1em 0.4em 1.5em;
      }

      @media (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 13.5em);
      }
      @media (min-width: $tiny-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 13.5em);
      }
      @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 15em);
      }
      @media (min-width: $smaller-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 13.5em);
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 15.5em);
      }
      @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 14em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        max-height: calc(100vh - 19.7em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 14.8em);
      }
      @media (min-width: $medium-less-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 13.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
        max-height: calc(100vh - 20.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
        max-height: calc(100vh - 18.5em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
        max-height: calc(100vh - 15em);
      }
      @media (min-width: $medium-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
        max-height: calc(100vh - 13.4em);
      }

      &__header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        // font-family: 'Black Ops One', cursive;
        font-family: 'Black Ops One', fantasy;
        color: white;
        font-size: 1.7em;
        padding: 0.3em 0.5em 0.2em;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.6em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 1.5em;
        }

        @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 1.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.1em;
        }

        &__title-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: center;
        }

        &__indicators-wrapper {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-end;

          @media (max-width: $medium-less-width-breakpoint) {
            flex-flow: column nowrap;
            align-items: center;
            justify-content: flex-start;
          }
        }

        &__end-date {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: flex-start;

          &__label {
            color: white;
            font-size: 0.6em;
            text-transform: uppercase;
          }

          &__text {
            font-size: 0.8em;
          }
        }

        &__sub-title {
          font-size: 0.75em;
          color: #ff8585;
          margin: 0 0 0 1em;
        }
      }

      &__content {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        background: white;
        padding: 0.4em;
        border-radius: 0 0 5px 5px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $default-black-color;
          border-radius: 0.4em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          border-radius: 0 0 3px 3px;
        }

        @media (max-width: $tiny-width-breakpoint) {
          padding: 0.3em 0.2em;
        }
      }

      &__no-entry-message {
        font-family: 'Montserrat', sans-serif;
        color: $default-black-color;
        text-align: center;
        font-size: 1.2em;
      }
    }

    &__training-option {
      &,
      &--highlighted {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-start;
        font-family: 'Montserrat', sans-serif;
        border: 1px solid $default-black-color;
        border-radius: 3px;
        min-width: 35em;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.95em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          font-size: 0.9em;
        }

        @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
          font-size: 0.88em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          font-size: 0.8em;
        }

        &:not(:first-child) {
          margin: 0.5em 0 0;
        }
      }

      &--highlighted {
        border-color: $site-red-color;
      }

      &__date,
      &__label {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1.3em;
        background: $default-black-color;
        border-radius: 3px 0 0 3px;
        color: white;
        padding: 0.3em;
        margin: 0 0.4em 0 0;
        flex-shrink: 0;
        text-align: center;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 1.2em;
        }

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          padding: 0;
          font-size: 1.15em;
        }

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          font-size: 0.9em;
        }

        &__date {
          font-size: 0.7em;
        }
      }

      &--highlighted &__label {
        background: $site-red-color;
      }

      &__date {
        width: 7em;

        @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
          width: 6.5em;
        }

        @media (max-width: $tiny-width-breakpoint) {
          width: 3.8em;
        }
      }

      &__label {
        width: 15em;
      }

      &__text-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: center;
        margin: 0.5em 0 0.2em;
        padding: 0 0 0.3em;
        text-align: justify;
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $site-red-color;
          border-radius: 0.4em;
        }
      }

      &__note-label {
        color: $site-red-color;
        font-weight: bold;
        margin: 0 0.4em 0 0;

        @media (max-width: $medium-less-width-breakpoint) {
          margin: 0 0 0.2em;
        }
      }

      &__text {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.9em;
        white-space: pre-wrap;

        @media (max-width: $medium-width-breakpoint) {
          font-size: 0.85em;
        }

        @media (max-width: $medium-less-width-breakpoint) {
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
        }

        @media (max-width: $small-width-breakpoint) {
          font-size: 0.8em;
        }

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.75em;
        }
      }

      &__notification {
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.8em;
        color: $site-red-color;
        text-transform: uppercase;
        font-weight: bold;

        @media (max-width: $smaller-width-breakpoint) {
          font-size: 0.7em;
        }
      }

      &__action-container {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: flex-end;
        flex: 1;
      }

      &__action {
        font-size: 1.4em;
        border-radius: 3px;
        box-shadow: none;
        padding: 0.4em;
        flex: none;
        margin: 0.2em 0 0.2em 0.5em;

        @media (max-width: $very-small-width-breakpoint) {
          font-size: 1.3em;
        }

        &:not(:first-child) {
          margin-left: 0.5em;

          @media (max-width: $smaller-width-breakpoint) {
            margin-left: 0.4em;
          }
        }

        &:last-child {
          margin-right: 0.2em;
        }
      }
    }

    &__student-note {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;

      &:not(:first-child) {
        margin-top: 0.5em;
      }

      @media (max-width: $small-width-breakpoint), (max-height: $high-height-breakpoint) {
        font-size: 0.9em;
      }

      &__header {
        background: #a2a2a2;
        border: 1px solid #a2a2a2;
        color: #5d5d5d;
        cursor: pointer;

        &,
        &--disabled {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 0.4em 0.8em;
          -webkit-tap-highlight-color:  transparent;

          &__text {
            font-weight: lighter;
          }

          &__text-icon {
            font-size: 0.9em;
            margin: 0 0.5em 0 0;
          }
        }

        &--disabled {
          background: #b5b5b5;
          color: #5a5a5a;
          cursor: default;
        }

        &__disabled-text {
          text-transform: uppercase;
        }
      }

      &__content {
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $default-black-color;
          border-radius: 0.4em;
        }
      }

      &__content-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 1em;
        border: 1px solid #a2a2a2;
        border-top-width: 0;
        background: #f3f3f3;

        @media (max-width: $smaller-width-breakpoint) {
          padding: 0.6em;
        }
      }

      &__text {
        text-align: justify;
        white-space: pre-wrap;
        color: $default-black-color;
        padding: 0.2em 0 0.4em;
        overflow: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
          width: 0.6em;
          height: 0.6em;

          @media (max-width: $medium-less-width-breakpoint) {
            width: 0.4em;
            height: 0.4em;
          }
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
          border-radius: 0.4em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #5d5d5d;
          border-radius: 0.4em;
        }
      }
    }

    &__progress-indicator {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 3.8em;
      height: 3.8em;
      background: center / contain no-repeat url(../../images/progress_background.svg);
      flex-shrink: 0;
      margin: 0 0 0 0.5em;

      @media (max-width: $medium-less-width-breakpoint) {
        width: 3.5em;
        height: 3.5em;
        margin: 0.2em 0;
      }

      &__text {
        font-family: 'Orbitron', sans-serif;
        font-weight: bold;
        font-size: 0.65em;
        color: white;
      }
    }
  }

  &__access-qr-code {
    width: 5em;

    @media (max-width: $medium-width-breakpoint) {
      width: 4em;
    }
    @media (max-width: $medium-less-width-breakpoint) {
      width: 3em;
    }
    @media (max-width: $small-width-breakpoint) {
      width: 2.5em;
    }

    &,
    &--expanded {
      position: absolute;
      background: white;
      border-radius: 1em;
      right: 1em;
      top: 0.4em;
      cursor: pointer;
      // z-index: 2;
      transition: $default-animation-period all;
      transform-origin: center;
      transform: translate(0, 0);
      max-width: 50em;

      @media (max-width: $medium-less-width-breakpoint) {
        border-radius: 5px;
      }
    }

    &--expanded {
      transform: translate(50%, -50%);
      width: 20%;
      box-shadow: 0px 0px 20px 5px $site-red-color;
      right: 50%;
      top: 50%;
    }

    &__img {
      width: 100%;
      max-width: 100%;
    }
  }

  &__parsed-text {
    white-space: pre;

    &__icon {
      text-shadow: none;
    }
  }

  &__training-group-not-configured {
    color: $default-black-color;
    font-weight: bold;
    background: $default-alert-color;
    border-radius: 3px;
    padding: 0.5em;
    text-align: center;
    margin: 0.5em;
  }

  &__unified-group-view {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    gap: 1em 0.5em;
    overflow: auto;
    margin: 3.4em 0 0;
    max-height: calc(100vh - 18.5em);

    @media (max-width: $large-width-breakpoint) {
      max-height: calc(100vh - 17em);
    }
    @media (max-width: $medium-width-breakpoint) {
      max-height: calc(100vh - 14.5em);
    }
    @media (max-width: $medium-less-width-breakpoint) {
      max-height: calc(100vh - 14.2em);
    }
    @media (max-width: $small-width-breakpoint) {
      max-height: calc(100vh - 12.7em);
    }
    @media (max-width: $smaller-width-breakpoint) {
      max-height: calc(100vh - 12.3em);
    }
    // @media (max-width: $tiny-width-breakpoint) {
    //   max-height: calc(100vh - 14.5em);
    // }

    // @media (max-height: $tiny-height-breakpoint) {
    //   max-height: calc(100vh - 13.5em);
    // }
    // @media (min-width: $tiny-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
    //   max-height: calc(100vh - 13.5em);
    // }
    @media (min-width: $smaller-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 12.3em);
    }
    // @media (min-width: $smaller-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
    //   max-height: calc(100vh - 13.5em);
    // }
    @media (min-width: $small-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 13em);
    }
    // @media (min-width: $small-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
    //   max-height: calc(100vh - 14em);
    // }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 12.5em);
    }
    @media (min-width: $medium-less-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 11em);
    }
    // @media (min-width: $medium-less-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
    //   max-height: calc(100vh - 13.5em);
    // }
    @media (min-width: $medium-width-breakpoint) and (max-height: $high-height-breakpoint) {
      max-height: calc(100vh - 14.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $medium-height-breakpoint) {
      max-height: calc(100vh - 12.5em);
    }
    @media (min-width: $medium-width-breakpoint) and (max-height: $small-height-breakpoint) {
      max-height: calc(100vh - 11em);
    }
    // @media (min-width: $medium-width-breakpoint) and (max-height: $tiny-height-breakpoint) {
    //   max-height: calc(100vh - 13.4em);
    // }

    &::-webkit-scrollbar {
      width: 1.5em;
      height: 1.5em;

      @media (max-width: $medium-less-width-breakpoint) { //, (max-height: $medium-height-breakpoint)
        width: 0.4em;
        height: 0.4em;
      }

      @media (max-width: $smaller-width-breakpoint) { //, (max-height: $small-height-breakpoint)
        width: 0.2em;
        height: 0.2em;
      }
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
      border-radius: 0.2em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $default-black-color;
      border-radius: 0.2em;
    }

    &__clock-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-start;
      border: 2px solid $default-black-color;
      border-radius: 4px;
      background: linear-gradient(140deg,#797979, #bbbbbb 80%,#797979);
      padding: 0 0.2em 0.1em;
    }

    &__clock {
      &__title {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: baseline;
        font-family: 'Black Ops One', cursive;
        text-align: center;
        background-color: $default-black-color;
        // color: transparent;
        // text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.54);
        background-clip: text !important;
        color: $default-black-color;
        padding: 0.1em 0;
      }
    }
  }
}
