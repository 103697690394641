@import '../constants.scss';
@import '../size_variables.scss';
@import '../color_variables.scss';

$default-animation-period: 0.4s;

.training_clock {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: center;
  background: $default-black-color;
  border-radius: 0.5em;
  padding: 0.2em;
  will-change: transform;
  transform-origin: top right;
  transition: $default-animation-period;

  @media (max-width: $large-width-breakpoint) {
    font-size: 0.9em;
  }

  @media (max-width: $medium-width-breakpoint), (max-height: $high-height-breakpoint) {
    font-size: 0.7em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $medium-height-breakpoint) {
    font-size: 0.54em;
  }

  @media (max-width: $small-width-breakpoint) {
    font-size: 0.45em;
    border-radius: 3px;
    padding: 1px;
  }

  &__time-wrapper,
  &__lap-wrapper {
    // background: radial-gradient(#ff8585, #d05353);

    &,
    &--red,
    &--green,
    &--yellow,
    &--blue {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.5em 2.2em;

      background: black;

      @media (max-width: $small-width-breakpoint) {
        font-size: 0.88em;
      }

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        font-size: 0.65em;
        padding: 0.5em 1.5em;
      }
    }
  }

  &__time-wrapper {
    &,
    &--red,
    &--green,
    &--yellow,
    &--blue {
      border-radius: 0 0.5em 0.5em 0;
      cursor: pointer;
    }
  }

  &__lap-wrapper {
    &,
    &--red,
    &--green,
    &--blue,
    &--yellow {
      margin: 0 1em 0 0;
    }
  }

  &__time-wrapper,
  &__lap-wrapper {
    &,
    &--red,
    &--green,
    &--yellow,
    &--blue {
      &.rounded {
        border-radius: 0.5em;
      }
    }
  }

  &__time-wrapper &__compress-button,
  &__time-wrapper--red &__compress-button,
  &__time-wrapper &__time,
  &__time-wrapper--red &__time,
  &__lap-wrapper &__lap,
  &__lap-wrapper--red &__lap {
    &,
    &--centered {
      // background-color: #af2626;

      color: #d62323;
    }
  }

  &__time-wrapper--green &__compress-button,
  &__time-wrapper--green &__time,
  &__lap-wrapper--green &__lap {
    &,
    &--centered {
      // background-color: #3c791d;

      color: #89f155;
    }
  }

  &__time-wrapper--yellow &__compress-button,
  &__time-wrapper--yellow &__time,
  &__lap-wrapper--yellow &__lap {
    &,
    &--centered {
      // background-color: #7d7b1d;

      color: #dad61e;
    }
  }

  &__time-wrapper--blue &__compress-button,
  &__time-wrapper--blue &__time,
  &__lap-wrapper--blue &__lap {
    &,
    &--centered {
      // background-color: #33798e;

      color: #1a9fc7;
    }
  }

  &__time,
  &__lap {
    &,
    &--centered {
      font-family: 'Orbitron', sans-serif;
      font-size: 7em;
      color: transparent;
      // text-shadow: -0.055em -0.055em 1px rgba(255, 255, 255, 0.85);
      // background-clip: text !important;
      text-transform: uppercase;
      font-weight: bold;
      user-select: none;
    }

    &--centered {
      text-align: center;
    }
  }

  &__lap {
    @media (max-width: $tiny-width-breakpoint) {
      text-align: center;
    }
  }

  &__time {
    &,
    &--centered {
      width: 3.7em;
    }
  }

  &__controls {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 0 2.2em;

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      padding: 0 1em;
    }
  }

  &__control-button {
    padding: 0.3em;
    box-shadow: none;
    font-size: 3.8em;

    @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
      font-size: 2.5em;
    }

    &:not(:last-of-type) {
      margin: 0 0.7em 0 0;

      @media (max-width: $smaller-width-breakpoint), (max-height: $small-height-breakpoint) {
        margin: 0 0.4em 0 0;
      }
    }
  }

  &__compress-button {
    font-size: 1.2em;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    outline: none;
    -webkit-tap-highlight-color:  transparent;
    user-select: none;
    text-decoration: none;
    min-width: 35px;
    min-height: 35px;
    border-radius: 0.5em;
    cursor: pointer;
  }
}
