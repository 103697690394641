@import '../color_variables.scss';
@import '../size_variables.scss';

.main-menu {
  flex: 1;
  padding: 7em 5em 4em;
  display: grid;
  grid-template:
    "last_class student_checkin" auto
    "start_resume daily_report" auto
    / 1fr 1fr;
  justify-items: stretch;
  align-items: stretch;

  @media (max-width: $medium-width-breakpoint) {
    padding: 6em 3.5em 3em;
  }

  @media (max-height: $high-height-breakpoint) {
    padding: 4.5em 3.5em 2.5em;
  }

  @media (max-width: $medium-less-width-breakpoint) {
    padding: 5em 1em 2em;
    grid-template:
      "last_class" auto
      "student_checkin" auto
      "start_resume" auto
      "daily_report" auto
      / 1fr;
  }

  @media (max-width: $medium-less-width-breakpoint) and (max-height: $high-height-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-width: $small-width-breakpoint) {
    padding: 4em 1em 1.5em;
  }

  @media (max-height: $small-height-breakpoint), (max-width: $smaller-width-breakpoint) {
    padding: 2.5em 0.5em 0.5em;
  }

  .last-class-link {
    grid-area: last_class;
  }

  .student-checkin-link {
    grid-area: student_checkin;
  }

  .start-resume-class-link {
    grid-area: start_resume;
  }

  .daily-report-link {
    grid-area: daily_report;
  }

  &__link-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    padding: 1.5em;

    @media (max-width: $medium-width-breakpoint) {
      padding: 0.8em;
    }

    @media (max-width: $smaller-width-breakpoint), (max-height: $medium-height-breakpoint) {
      padding: 0.5em;
    }

    @media (max-width: $very-small-width-breakpoint), (max-height: $tiny-height-breakpoint) {
      padding: 0.3em;
    }
  }
}
