@import '../color_variables.scss';
@import '../size_variables.scss';

.default-filter-input {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  margin: 1em 0;

  @media (max-width: $medium-width-breakpoint) {
    font-size: 0.9em;
  }

  @media (max-width: $medium-less-width-breakpoint), (max-height: $high-height-breakpoint) {
    font-size: 0.75em;
  }

  @media (max-width: $small-width-breakpoint), (max-height: $medium-height-breakpoint) {
    font-size: 0.7em;
  }

  @media (max-width: $very-small-width-breakpoint) {
    font-size: 0.65em;
  }

  &__label,
  &__input {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Iceland', cursive;
    padding: 0.3em 0.3em 0.3em 0.6em;
    font-size: 2.2em;
  }

  &__label {
    background: $default-black-color;
    color: white;
    border-radius: 1em 0 0 1em;

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 4px 0 0 4px;
      padding-left: 0.3em;
    }
  }

  &__input {
    flex: 1;
    color: $default-black-color;
    border: 1px solid $default-black-color;
    outline: none;
    border-radius: 0 1em 1em 0;
    min-width: 10em;

    @media (max-width: $medium-width-breakpoint) {
      padding-left: 0.5em;
    }

    @media (max-width: $medium-less-width-breakpoint) {
      padding-left: 0.3em;
    }

    @media (max-width: $smaller-width-breakpoint) {
      border-radius: 0 4px 4px 0;
    }

    @media (max-width: $very-small-width-breakpoint) {
      padding: 0.2em;
    }
  }
}
