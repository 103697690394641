.main-menu {
  flex: 1 1;
  padding: 7em 5em 4em;
  display: grid;
  grid-template: "last_class student_checkin" auto "start_resume daily_report" auto / 1fr 1fr;
  justify-items: stretch;
  align-items: stretch; }
  @media (max-width: 1100px) {
    .main-menu {
      padding: 6em 3.5em 3em; } }
  @media (max-height: 850px) {
    .main-menu {
      padding: 4.5em 3.5em 2.5em; } }
  @media (max-width: 850px) {
    .main-menu {
      padding: 5em 1em 2em;
      grid-template: "last_class" auto "student_checkin" auto "start_resume" auto "daily_report" auto / 1fr; } }
  @media (max-width: 850px) and (max-height: 850px) {
    .main-menu {
      padding: 4em 1em 1.5em; } }
  @media (max-width: 680px) {
    .main-menu {
      padding: 4em 1em 1.5em; } }
  @media (max-height: 480px), (max-width: 510px) {
    .main-menu {
      padding: 2.5em 0.5em 0.5em; } }
  .main-menu .last-class-link {
    grid-area: last_class; }
  .main-menu .student-checkin-link {
    grid-area: student_checkin; }
  .main-menu .start-resume-class-link {
    grid-area: start_resume; }
  .main-menu .daily-report-link {
    grid-area: daily_report; }
  .main-menu__link-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    padding: 1.5em; }
    @media (max-width: 1100px) {
      .main-menu__link-wrapper {
        padding: 0.8em; } }
    @media (max-width: 510px), (max-height: 650px) {
      .main-menu__link-wrapper {
        padding: 0.5em; } }
    @media (max-width: 420px), (max-height: 380px) {
      .main-menu__link-wrapper {
        padding: 0.3em; } }
